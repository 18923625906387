<template>
  <div>
    <!-- modal visitor -->
    <b-form @submit.stop.prevent="onSubmitAddForm" v-if="isVisitor">
      <b-form-group
        v-if="uploadImageRequired()"
        id="input-group-face_image"
        label="Profile Picture:"
        label-for="input-face_image"
      >
        <!-- upload file -->
        <b-form-file
          id="input-face_image"
          accept="image/*"
          @change="onFileChange(formAdd, $event)"
          style="display: none"
        ></b-form-file>
        <b-button
          class="ml-2 mr-1"
          size="md"
          variant="secondary"
          v-b-tooltip.hover
          title="Upload Photo"
          @click="uploadPhoto"
        >
          <i class="fas fa-file-upload"></i>
        </b-button>
        <!-- upload file -->
        <!-- capture image -->
        <b-button
          class="ml-2 mr-1"
          size="md"
          variant="warning"
          v-b-tooltip.hover
          title="Take Photo"
          @click="getCapture"
        >
          <i class="fas fa-camera"></i>
        </b-button>
        <!-- capture image -->
        <div
          class="row justify-content-center mt-2"
          v-show="showTakePhoto == true"
        >
          <div
            id="live_video_container"
            ref="cameraView"
            v-show="capturedPhoto == null"
          ></div>
          <div
            id="photo_container"
            ref="photoView"
            v-show="capturedPhoto != null"
          ></div>
        </div>
        <div
          class="row justify-content-center mt-2"
          v-show="showUploadPhoto == true"
        >
          <div class="visitor-card-face-image">
            <img :src="showImage(formAdd.face_image)" fluid />
          </div>
        </div>
        <div
          class="row justify-content-center mt-2"
          v-if="showTakePhoto == true"
        >
          *Note : Please uncover and position your face in front of the camera.
        </div>
        <!-- take photo -->
        <div
          class="row justify-content-center mt-2"
          v-if="showTakePhoto == true"
        >
          <b-button
            variant="secondary"
            @click="retakePhoto"
            v-if="capturedPhoto != null"
            >Retake Photo
          </b-button>
          <b-button
            variant="primary"
            @click="takePhoto"
            v-if="capturedPhoto == null"
            >Take Photo
          </b-button>
          <b-button
            variant="warning"
            class="ml-2 mr-1"
            v-b-tooltip.hover
            @click="getCapture"
            v-if="capturedPhoto == null"
          >
            <i class="fa-solid fa-camera-rotate"></i>
          </b-button>
          <!-- <b-button variant="danger" @click="cancelTakePhoto" class="ml-2"
                  >Cancel
                </b-button> -->
        </div>
        <!-- take photo -->
        <!-- remove photo -->
        <div
          class="row justify-content-center mt-2"
          v-if="showUploadPhoto == true"
        >
          <b-button variant="danger" @click="reuploadPhoto"
            >Remove Photo
          </b-button>
          <!-- <b-button variant="danger" @click="cancelUploadPhoto" class="ml-2"
                  >Cancel
                </b-button> -->
        </div>
        <!-- remove photo -->
      </b-form-group>

      <b-form-group
        id="input-group-name"
        label="Full name:"
        label-for="input-name"
      >
        <b-form-input
          v-model="$v.formAdd.name.$model"
          placeholder="Enter name"
          :state="validateFormAdd('name')"
          aria-describedby="formAdd.name_feedback"
        ></b-form-input>
        <b-form-invalid-feedback
          class="invalid-feedback"
          id="formAdd.name_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-company"
        label="Company:"
        label-for="input-company"
      >
        <b-form-input
          v-model="$v.companyVisitor.$model"
          placeholder="Enter company"
          :state="validateCompany()"
          aria-describedby="companyVisitor_feedback"
        ></b-form-input>
        <b-form-invalid-feedback
          class="invalid-feedback"
          id="companyVisitor_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-gender"
        label="Gender"
        label-for="input-gender"
      >
        <b-form-select
          id="input-gender"
          v-model="selectedVisitorGender"
          :options="visitorGenderOptions"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-email"
        label="Email:"
        label-for="input-email"
      >
        <b-form-input
          id="input-email"
          v-model="$v.formAdd.email.$model"
          placeholder="example@email.com"
          :state="validateFormAdd('email')"
          aria-describedby="formAdd.email_feedback"
          @keyup="handleLowercase"
        ></b-form-input>
        <b-form-invalid-feedback
          v-if="!$v.formAdd.email.required"
          class="invalid-feedback"
          id="formAdd.email_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
        <b-form-invalid-feedback
          v-if="!$v.formAdd.email.email"
          class="invalid-feedback"
          id="formAdd.email_feedback"
        >
          Needs to be a valid email. Example : example@email.com
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="
          getPreference.additional_metadata
            .vms_credential_operator_license_plate_recognition
        "
        id="input-group-car_plate"
        label="Car Plate No :"
        label-for="input-car_plate"
      >
        <b-form-input
          id="input-car_plate_no"
          v-model="$v.carPlateNo.$model"
          placeholder="Enter Car Plate No: ABC123"
          :state="validateCarPlateNo()"
          aria-describedby="formAdd.car_plate_no_feedback"
        ></b-form-input
        >NOTE : Key in "-" if no license plate number.
        <b-form-invalid-feedback
          class="invalid-feedback"
          id="formAdd.car_plate_no_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="
          !getPreference.additional_metadata
            .vms_credential_operator_license_plate_recognition
        "
        id="input-group-car_plate"
        label="Car Plate No :"
        label-for="input-car_plate"
      >
        <b-form-input
          v-model="formAdd.profile.car_plate_no"
          placeholder="Car Plate No: ABC123"
        ></b-form-input
        >NOTE : Key in "-" if no license plate number.
      </b-form-group>

      <b-form-group
        id="input-group-contact_number"
        label="Contact Number:"
        label-for="input-contact_number"
      >
        <VuePhoneNumberInput
          v-bind="vuePhone.props"
          v-model="$v.contactNumber.$model"
          @input="handleRegexPhoneNumber"
          :state="validateContactNumber()"
          aria-describedby="formAdd_contact_number_feedback"
        />
        <div
          v-if="result == false"
          class="invalid-contact-number-feedback"
          id="formAdd_contact_number_feedback"
        >
          This field is required.
        </div>
      </b-form-group>

      <b-form-group
        id="input-group-national-id"
        label="National ID Type:"
        label-for="input-national-id"
      >
        <b-row no-gutters>
          <b-col sm="5">
            <b-form-select
              id="select-national-id"
              v-model="selectedIDType"
              :options="nationalIDOptions"
              required
              @change="handleChangeID"
            ></b-form-select>
          </b-col>
          <b-col sm="7">
            <b-form-input
              id="input-national-id"
              class="mb-1"
              v-model="selectedIDName"
              @input="handleInput(selectedIDType, selectedIDName)"
              :state="validateFormAdd(selectedIDType)"
              :aria-describedby="selectedIDType"
              placeholder="Please input......."
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="
                selectedIDType === 'nric_number' &&
                !$v.formAdd.nric_number.required
              "
              class="invalid-feedback"
              :id="selectedIDType"
            >
              This field is required.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="
                selectedIDType === 'passport_number' &&
                !$v.formAdd.passport_number.required
              "
              class="invalid-feedback"
              :id="selectedIDType"
            >
              This field is required.
            </b-form-invalid-feedback>
          </b-col>
          <b-col>
            <!-- a visitor's NRID (MyKad) or passport number" -->
            <div class="d-flex justify-content-end">
              <b-button
                v-b-tooltip.hover
                title="Click this button to scan"
                variant="secondary"
                @click="readIcCardInfo"
                :disabled="isDisabled"
                v-show="
                  getPreference.additional_metadata.vms_enable_biometric_scanner
                "
                >Scan
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <div
            class="d-flex justify-content-start text-danger"
            v-if="scanAddErrorMessage != null"
          >
            {{ scanAddErrorMessage }}
          </div>
        </b-row>
      </b-form-group>

      <b-form-group
        id="input-group-fingerprint"
        label="Fingerprint:"
        label-for="input-fingerprint"
        v-if="
          getPreference.additional_metadata.vms_credential_operator_fingerprint
        "
      >
        <table>
          <tr>
            <td>
              <b-button
                v-b-tooltip.hover
                title="Click this button to scan finger"
                variant="primary"
                @click="readFingerprint"
                >Scan Finger
              </b-button>
            </td>
            <td>
              <span v-if="fingerprint1">
                fingerprint template 1
                <i
                  class="far fa-window-close ml-3"
                  @click="deleteFingerprintHandler('template1')"
                ></i></span
              ><br />
              <span v-if="fingerprint2">
                fingerprint template 2
                <i
                  class="far fa-window-close ml-3"
                  @click="deleteFingerprintHandler('template2')"
                ></i
              ></span>
            </td>
            <td>
              <span v-if="!fingerprint1 && !fingerprint2"> No fingerprint</span>
            </td>
          </tr>
        </table>
        <div
          v-if="
            isFingerprintRequired &&
            formAdd.profile.fingerprint.template1 == null &&
            formAdd.profile.fingerprint.template2 == null
          "
          class="invalid-fingerprint-feedback"
          id="formAdd_fingerprint_feedback"
        >
          Finger print is required.
        </div>
      </b-form-group>

      <div class="text-danger" v-if="formAddErrorMessage != null">
        {{ formAddErrorMessage }}
      </div>

      <div class="text-right">
        <b-button type="submit" variant="primary">Submit</b-button>
      </div>
    </b-form>

    <!-- modal register -->
    <b-form
      @submit.stop.prevent="onSubmitAddForm"
      v-if="isRegister || isPublic"
    >
      <b-form-group
        v-if="uploadImageRequired()"
        id="input-group-profile-picture"
        label="Profile Picture:"
        label-for="input-profile-picture"
      >
        <b-button
          class=""
          size="md"
          variant="warning"
          v-b-tooltip.hover
          title="Take Photo"
          @click="showAddVisitorImageModal"
          :disabled="allow && isPublic"
        >
          <i class="fas fa-camera"></i>
        </b-button>
      </b-form-group>
      <div
        class="row justify-content-center mt-2"
        v-if="uploadImageRequired() && capturedPhoto != null"
        @click="showAddVisitorImageModal"
      >
        <div class="visitor-card-face-image">
          <img :src="showImage(formAdd.face_image)" fluid />
        </div>
      </div>

      <b-form-group
        id="input-group-name"
        label="Full name:"
        label-for="input-name"
      >
        <b-form-input
          v-model="$v.formAdd.name.$model"
          placeholder="Enter name"
          :state="validateFormAdd('name')"
          aria-describedby="formAdd.name_feedback"
          :disabled="allow && isPublic"
        ></b-form-input>
        <b-form-invalid-feedback
          class="invalid-feedback"
          id="formAdd.name_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-company"
        label="Company:"
        label-for="input-company"
      >
        <b-form-input
          v-model="$v.companyVisitor.$model"
          placeholder="Enter company"
          :state="validateCompany()"
          aria-describedby="companyVisitor_feedback"
          :disabled="allow && isPublic"
        ></b-form-input>
        <b-form-invalid-feedback
          class="invalid-feedback"
          id="companyVisitor_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-gender"
        label="Gender"
        label-for="input-gender"
      >
        <b-form-select
          id="input-gender"
          v-model="selectedVisitorGender"
          :options="visitorGenderOptions"
          :disabled="allow && isPublic"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-email"
        label="Email:"
        label-for="input-email"
      >
        <b-form-input
          id="input-email"
          v-model="$v.formAdd.email.$model"
          placeholder="example@email.com"
          :state="validateFormAdd('email')"
          aria-describedby="formAdd.email_feedback"
          @keyup="handleLowercase"
          :disabled="allow && isPublic"
        ></b-form-input>
        <b-form-invalid-feedback
          v-if="!$v.formAdd.email.required"
          class="invalid-feedback"
          id="formAdd.email_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
        <b-form-invalid-feedback
          v-if="!$v.formAdd.email.email"
          class="invalid-feedback"
          id="formAdd.email_feedback"
        >
          Needs to be a valid email. Example : example@email.com
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- create-visit -->
      <b-form-group
        v-if="
          isRegister &&
          parentUrl === 'create-visit' &&
          getPreference.additional_metadata
            .vms_credential_operator_license_plate_recognition
        "
        id="input-group-car_plate"
        label="Car Plate No :"
        label-for="input-car_plate"
      >
        <b-form-input
          id="input-car_plate_no"
          v-model="$v.carPlateNo.$model"
          placeholder="Enter Car Plate No: ABC123"
          :state="validateCarPlateNo()"
          aria-describedby="formAdd.car_plate_no_feedback"
        ></b-form-input
        >NOTE : Key in "-" if no license plate number.
        <b-form-invalid-feedback
          class="invalid-feedback"
          id="formAdd.car_plate_no_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="
          isRegister &&
          parentUrl === 'create-visit' &&
          !getPreference.additional_metadata
            .vms_credential_operator_license_plate_recognition
        "
        id="input-group-car_plate"
        label="Car Plate No :"
        label-for="input-car_plate"
      >
        <b-form-input
          v-model="formAdd.profile.car_plate_no"
          placeholder="Car Plate No: ABC123"
          :disabled="allow && isPublic"
        ></b-form-input>
        NOTE : Key in "-" if no license plate number.
      </b-form-group>

      <!-- kiosk2 -->
      <b-form-group
        v-if="
          isRegister &&
          parentUrl === 'kiosk2' &&
          getPreference.additional_metadata
            .vms_credential_kiosk_license_plate_recognition
        "
        id="input-group-car_plate"
        label="Car Plate No :"
        label-for="input-car_plate"
      >
        <b-form-input
          id="input-car_plate_no"
          v-model="$v.carPlateNo.$model"
          placeholder="Enter Car Plate No: ABC123"
          :state="validateCarPlateNo()"
          aria-describedby="formAdd.car_plate_no_feedback"
        ></b-form-input
        >NOTE : Key in "-" if no license plate number.
        <b-form-invalid-feedback
          class="invalid-feedback"
          id="formAdd.car_plate_no_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="
          isRegister &&
          parentUrl === 'kiosk2' &&
          !getPreference.additional_metadata
            .vms_credential_kiosk_license_plate_recognition
        "
        id="input-group-car_plate"
        label="Car Plate No :"
        label-for="input-car_plate"
      >
        <b-form-input
          v-model="formAdd.profile.car_plate_no"
          placeholder="Car Plate No: ABC123"
          :disabled="allow && isPublic"
        ></b-form-input>
        NOTE : Key in "-" if no license plate number.
      </b-form-group>

      <!-- public -->
      <b-form-group
        v-if="
          isPublic &&
          public_preference.additional_metadata
            .vms_credential_operator_license_plate_recognition
        "
        id="input-group-car_plate"
        label="Car Plate No :"
        label-for="input-car_plate"
      >
        <b-form-input
          id="input-car_plate_no"
          v-model="$v.carPlateNo.$model"
          placeholder="Enter Car Plate No: ABC123"
          :state="validateCarPlateNo()"
          aria-describedby="formAdd.car_plate_no_feedback"
        ></b-form-input
        >NOTE : Key in "-" if no license plate number.
        <b-form-invalid-feedback
          class="invalid-feedback"
          id="formAdd.car_plate_no_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="
          isPublic &&
          !public_preference.additional_metadata
            .vms_credential_operator_license_plate_recognition
        "
        id="input-group-car_plate"
        label="Car Plate No :"
        label-for="input-car_plate"
      >
        <b-form-input
          v-model="formAdd.profile.car_plate_no"
          placeholder="Car Plate No: ABC123"
          :disabled="allow && isPublic"
        ></b-form-input>
        NOTE : Key in "-" if no license plate number.
      </b-form-group>

      <b-form-group
        id="input-group-contact_number"
        label="Contact Number:"
        label-for="input-contact_number"
      >
        <VuePhoneNumberInput
          v-bind="vuePhone.props"
          v-model="$v.contactNumber.$model"
          @input="handleRegexPhoneNumber"
          :state="validateContactNumber()"
          aria-describedby="contact_number_feedback"
          :disabled="allow && isPublic"
        />
        <div
          v-if="result == false"
          class="invalid-contact-number-feedback"
          id="contact_number_feedback"
        >
          This field is required.
        </div>
      </b-form-group>

      <b-form-group
        id="input-group-national-id"
        label="National ID Type"
        label-for="input-national-id"
      >
        <b-row no-gutters>
          <b-col sm="5">
            <b-form-select
              id="select-national-id"
              v-model="selectedIDType"
              :options="nationalIDOptions"
              @change="handleChangeID"
              required
              :disabled="allow && isPublic"
            ></b-form-select>
          </b-col>
          <b-col sm="7">
            <b-form-input
              id="input-national-id"
              v-model="selectedIDName"
              @input="handleInput(selectedIDType, selectedIDName)"
              :state="validateFormAdd(selectedIDType)"
              :aria-describedby="selectedIDType"
              placeholder="Please input......."
              class="mb-1"
              :disabled="allow && isPublic"
            ></b-form-input>

            <b-form-invalid-feedback
              v-if="
                selectedIDType === 'nric_number' &&
                !$v.formAdd.nric_number.required
              "
              class="invalid-feedback"
              :id="selectedIDType"
            >
              This field is required.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="
                selectedIDType === 'passport_number' &&
                !$v.formAdd.passport_number.required
              "
              class="invalid-feedback"
              :id="selectedIDType"
            >
              This field is required.
            </b-form-invalid-feedback>
          </b-col>
          <b-col v-if="isRegister">
            <!-- a visitor's NRID (MyKad) or passport number" -->
            <div class="d-flex justify-content-end">
              <b-button
                v-b-tooltip.hover
                title="Click this button to scan"
                variant="secondary"
                @click="readIcCardInfo"
                :disabled="isDisabled"
                v-show="
                  getPreference.additional_metadata.vms_enable_biometric_scanner
                "
                >Scan</b-button
              >
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <div
            class="d-flex justify-content-start text-danger"
            v-if="scanErrorMessage != null"
          >
            {{ scanErrorMessage }}
          </div>
        </b-row>
      </b-form-group>

      <b-form-group
        id="input-group-fingerprint"
        label="Fingerprint:"
        label-for="input-fingerprint"
        v-if="
          (parentUrl === 'create-visit' &&
            getPreference.additional_metadata
              .vms_credential_operator_fingerprint) ||
          (parentUrl === 'kiosk2' &&
            getPreference.additional_metadata.vms_credential_kiosk_fingerprint)
        "
      >
        <table>
          <tr>
            <td>
              <b-button
                v-b-tooltip.hover
                title="Click this button to scan finger"
                variant="primary"
                @click="readFingerprint"
                >Scan Finger
              </b-button>
            </td>
            <td>
              <span v-if="fingerprint1">
                fingerprint template 1
                <i
                  class="far fa-window-close ml-3"
                  @click="deleteFingerprintHandler('template1')"
                ></i></span
              ><br />
              <span v-if="fingerprint2">
                fingerprint template 2
                <i
                  class="far fa-window-close ml-3"
                  @click="deleteFingerprintHandler('template2')"
                ></i
              ></span>
            </td>
            <td>
              <span v-if="!fingerprint1 && !fingerprint2"> No fingerprint</span>
            </td>
          </tr>
        </table>
        <div
          v-if="
            isFingerprintRequired &&
            formAdd.profile.fingerprint.template1 == null &&
            formAdd.profile.fingerprint.template2 == null
          "
          class="invalid-fingerprint-feedback"
          id="formAdd_fingerprint_feedback"
        >
          This field is required.
        </div>
      </b-form-group>

      <div class="text-danger" v-if="formAddErrorMessage != null">
        {{ formAddErrorMessage }}
      </div>

      <div class="text-right" v-if="isRegister">
        <b-button type="submit" variant="primary">Submit</b-button>
      </div>
    </b-form>

    <!-- add image -->
    <b-form v-if="isAddImage">
      <div
        class="row justify-content-center mt-2"
        v-show="capturedPhoto != null"
      >
        <div>
          <img :src="showImage(formAdd.face_image)" fluid />
        </div>
      </div>

      <div
        class="row justify-content-center mt-2"
        v-show="showTakePhoto == true"
      >
        <div
          id="live_video_container"
          ref="cameraView"
          v-show="capturedPhoto == null"
        ></div>
      </div>
      <div class="row justify-content-center mt-2" v-if="showTakePhoto == true">
        *Note : Please uncover and position your face in front of the camera.
      </div>
      <div class="d-flex justify-content-center" v-if="showTakePhoto">
        <!-- <b-button class="my-2 mx-1">Upload File</b-button> -->
        <b-button
          v-if="capturedPhoto != null"
          class="my-2 mx-1"
          @click="retakePhoto"
          >Retake Photo
        </b-button>
        <b-button
          v-if="capturedPhoto == null"
          class="my-2 mx-1"
          variant="primary"
          @click="takePhoto"
          >Take Photo
        </b-button>
        <b-button
          v-if="capturedPhoto == null"
          class="my-2 mx-1"
          size="md"
          variant="warning"
          v-b-tooltip.hover
          @click="getCapture"
        >
          <i class="fa-solid fa-camera-rotate"></i>
        </b-button>
      </div>
      <div class="mt-4 d-flex justify-content-end">
        <!-- <b-button class="mr-2" @click="cancelTakePhoto">Cancel</b-button> -->
        <b-button
          variant="primary"
          :disabled="capturedPhoto == null"
          @click="onSubmitUploadPictureForm"
          >Submit
        </b-button>
      </div>
      <div class="mt-4 d-flex justify-content-end"></div>
    </b-form>

    <!-- image modal -->
    <b-modal
      v-else
      id="form-add-visitor-image"
      ref="formAddVisitorImage"
      title="Profile Picture"
      centered
      hide-footer
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      @close="doneTakePhoto"
    >
      <b-form>
        <div
          class="row justify-content-center mt-2"
          v-show="capturedPhoto != null"
        >
          <div>
            <img :src="showImage(formAdd.face_image)" fluid />
          </div>
        </div>

        <div
          class="row justify-content-center mt-2"
          v-show="showTakePhoto == true"
        >
          <div
            id="live_video_container"
            ref="cameraView"
            v-show="capturedPhoto == null"
          ></div>
        </div>
        <div
          class="row justify-content-center mt-2"
          v-if="showTakePhoto == true"
        >
          *Note : Please uncover and position your face in front of the camera.
        </div>

        <div class="d-flex justify-content-center" v-if="showTakePhoto">
          <!-- <b-button class="my-2 mx-1">Upload File</b-button> -->
          <b-button
            v-if="capturedPhoto != null"
            class="my-2 mx-1"
            @click="retakePhoto"
            >Retake Photo
          </b-button>
          <b-button
            v-if="capturedPhoto == null"
            class="my-2 mx-1"
            variant="primary"
            @click="takePhoto"
            >Take Photo
          </b-button>
          <b-button
            v-if="capturedPhoto == null"
            class="my-2 mx-1"
            size="md"
            variant="warning"
            v-b-tooltip.hover
            @click="getCapture"
          >
            <i class="fa-solid fa-camera-rotate"></i>
          </b-button>
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <b-button variant="primary" @click="doneTakePhoto">OK</b-button>
        </div>
        <div class="mt-4 d-flex justify-content-end"></div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
  between,
  helpers
} from "vuelidate/lib/validators";

import VuePhoneNumberInput from "vue-phone-number-input";
import Multiselect from "vue-multiselect";

import "vue-phone-number-input/dist/vue-phone-number-input.css";
import jQuery from "jquery";

let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

import * as imageConversion from "image-conversion";

let convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

//const regex = helpers.regex(regex, /^[a-z0-9]+$/i);

export default {
  mixins: [validationMixin],
  components: {
    VuePhoneNumberInput
  },
  props: {
    referenceParent: {},
    public_preference: {},
    getPreference: {},
    getCountryCode: {},

    isVisitor: {
      type: Boolean
    },
    isPublic: {
      type: Boolean
    },
    isRegister: {
      type: Boolean
    },
    isAddImage: {
      type: Boolean
    },
    parentUrl: {
      type: String
    }
  },
  data: function () {
    return {
      nationalIDOptions: [],
      visitorGenderOptions: [
        { value: "male", text: "Male" },
        { value: "female", text: "Female" }
      ],

      formAdd: {
        face_image: null,
        name: null,
        email: null,
        nric_number: null,
        passport_number: null,
        contact_number: null,
        custom_id: null,
        profile: {
          gender: null,
          car_plate_no: null,
          company: null,
          fingerprint: {
            template1: null,
            template2: null
          }
        }
      },

      vuePhone: {
        phone: "",
        props: {
          clearable: true,
          defaultCountryCode: "MY",
          preferredCountries: ["SG", "ID"],
          noExample: true,
          translations: {
            countrySelectorLabel: "",
            countrySelectorError: "Error",
            example: "Example:",
            phoneNumberLabel: "Example: 188888888"
          }
        }
      },

      carPlateNo: null,
      companyVisitor: null,
      contactNumber: null,

      selectedIDName: null,
      selectedIDType: "passport_number",
      selectedVisitorGender: null,

      scanErrorMessage: null,
      formAddErrorMessage: null,

      showTakePhoto: false,
      showUploadPhoto: false,

      capturedPhoto: null,
      scanAddErrorMessage: null,

      isDisabled: false,
      countTakePhoto: 0,

      cameraType: null,
      result: null,

      allow: false,
      template1: null,
      template2: null,
      fingerprint1: false,
      fingerprint2: false,
      isFingerprintRequired: false
    };
  },
  computed: {
    ...mapGetters({
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  validations: {
    contactNumber: { required },
    companyVisitor: { required },
    carPlateNo: { required },
    template1: { required },
    template2: { required },

    formAdd: {
      name: { required },
      email: { required, email },
      passport_number: { required },
      nric_number: { required } //, regex_nric }
    }
  },
  mounted: function () {
    this.getNationalID();
  },
  methods: {
    getNationalID() {
      //this.getCountryCode = "IDN";
      //console.log("this.route", this.$route);
      let country_code = null;
      if (this.isPublic) {
        let API_URL = this.getAPIServerURL + "/public/vms/visit-preference/";
        const client = this.$root.getAjaxFetchClient();
        client
          .getRequest(API_URL)
          .then((data) => {
            console.log("public_preference add visitor", data);

            for (let key in data.additional_metadata.national_id_type) {
              if (this.getCountryCode == key) {
                country_code = key;
              }
            }

            for (let key2 in data.additional_metadata.national_id_type[
              country_code
            ]) {
              if (
                data.additional_metadata.national_id_type[country_code][key2] ==
                "Passport"
              ) {
                this.nationalIDOptions.push({
                  value: "passport_number",
                  text: data.additional_metadata.national_id_type[country_code][
                    key2
                  ]
                });
              } else {
                this.nationalIDOptions.push({
                  value: "nric_number",
                  text: data.additional_metadata.national_id_type[country_code][
                    key2
                  ]
                });
              }
            }
          })
          .catch((err) => {
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
          });
      } else {
        for (let key in this.getPreference.additional_metadata
          .national_id_type) {
          if (this.getCountryCode == key) {
            country_code = key;
          }
        }

        for (let key2 in this.getPreference.additional_metadata
          .national_id_type[country_code]) {
          if (
            this.getPreference.additional_metadata.national_id_type[
              country_code
            ][key2] == "Passport"
          ) {
            this.nationalIDOptions.push({
              value: "passport_number",
              text: this.getPreference.additional_metadata.national_id_type[
                country_code
              ][key2]
            });
          } else {
            this.nationalIDOptions.push({
              value: "nric_number",
              text: this.getPreference.additional_metadata.national_id_type[
                country_code
              ][key2]
            });
          }
        }
      }
    },
    // handle
    handleChangeID() {
      this.selectedIDName = null;
      this.formAdd.custom_id = null;
      this.formAdd.passport_number = null;
      this.formAdd.nric_number = null;
    },
    handleRegexPhoneNumber() {
      if (this.contactNumber != null) {
        this.contactNumber = this.contactNumber.replace(/-/g, "");
      }
      this.formAdd.contact_number = this.contactNumber;
    },
    handleInput(id, value) {
      if (id == "nric_number") {
        this.$v.formAdd.nric_number.$model = value;
      } else {
        this.$v.formAdd.passport_number.$model = value;
      }
    },
    handleCamera() {
      if (this.cameraType == "user") {
        this.cameraType = "environment";
        console.log("this.cameraType1", this.cameraType);

        this.stopCamera();
        this.removeCameraStream();
      } else if (this.cameraType == "environment") {
        this.cameraType = "user";
        console.log("this.cameraType2", this.cameraType);

        this.stopCamera();
        this.removeCameraStream();
      } else if (this.cameraType == null) {
        this.cameraType = "user";
        console.log("this.cameraType3", this.cameraType);
      }
    },
    handleLowercase() {
      if (this.formAdd.email != null) {
        this.formAdd.email = this.formAdd.email.toLowerCase();
      }
    },

    /** show **/
    showImage: function (img) {
      let $this = this;
      return img ? `data:image/jpeg;base64,${img}` : $this.defaultImage;
    },
    showAddVisitorImageModal() {
      this.getCapture();
      this.$bvModal.show("form-add-visitor-image");
    },

    /* upload photo */
    onFileChange: function (item, e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(item, files[0]);
    },
    createImage: function (item, file) {
      let $this = this;
      //let image = new Image();
      let reader = new FileReader();

      reader.onload = async (e) => {
        let base64Image2 = await this.scCompressBase64Image(e.target.result);

        item.face_image = base64Image2.substring(base64Image2.indexOf(",") + 1);

        $this.uploadedPhoto = item.face_image;
        $this.showUploadPhoto = true;
      };
      reader.readAsDataURL(file);
    },
    async scCompressBase64Image(base64Image) {
      const buffer = Buffer.from(
        base64Image.substring(base64Image.indexOf(",") + 1)
      );
      let base64Response = await fetch(base64Image);

      let blob = await base64Response.blob();

      let compressedBlob = await imageConversion.compressAccurately(blob, {
        size: 200, //The compressed image size is 100kb
        accuracy: 0.5, //the accuracy of image compression size,range 0.8-0.99,default 0.95;
        //this means if the picture size is set to 1000Kb and the
        //accuracy is 0.9, the image with the compression result
        //of 900Kb-1100Kb is considered acceptable;
        type: "image/jpeg",
        width: 350,
        height: 350,
        orientation: 2,
        scale: 0.8
      });

      let compressedBase64String = await convertBlobToBase64(compressedBlob);
      const buffer2 = Buffer.from(
        compressedBase64String.substring(
          compressedBase64String.indexOf(",") + 1
        )
      );

      return compressedBase64String;
    },
    uploadPhoto: function () {
      let $this = this;
      $this.countTakePhoto = 0;
      $this.capturedPhoto = null;

      $this.showTakePhoto = false;

      $this.stopCamera();
      $this.removeCameraStream();
      $this.removePhotoView();

      if (document.getElementById("input-face_image").value != "") {
        document.getElementById("input-face_image").value = "";
      }

      document.getElementById("input-face_image").click();
    },
    reuploadPhoto: function () {
      let $this = this;

      $this.showUploadPhoto = false;
      $this.countTakePhoto = 0;

      $this.uploadedPhoto = null;
      $this.formAdd.face_image = null;
    },
    /* cancelUploadPhoto: function () {
        let $this = this;
  
        $this.showUploadPhoto = false;
        $this.countTakePhoto = 0;
  
        $this.uploadedPhoto = null;
        $this.formAdd.face_image = null;
      }, */
    removePhotoView: function () {
      let $this = this;
      if ($this.$refs.photoView != null) {
        while ($this.$refs.photoView.hasChildNodes()) {
          $this.$refs.photoView.removeChild($this.$refs.photoView.lastChild);
        }
      }
    },

    async getCapture() {
      this.handleCamera();
      if (this.formAdd.face_image != null && this.showUploadPhoto == true) {
        this.$bvModal
          .msgBoxConfirm(
            "A profile picture is already uploaded, do you wish to remove the uploaded picture and take a new picture?",
            {
              okTitle: "Yes",
              cancelTitle: "No",
              centered: true
            }
          )
          .then(async (value) => {
            if (value) {
              if (this.countTakePhoto == 0) {
                this.getWebCamDeviceList();
              }
            }
          })
          .catch((err) => {
            // An error occurred
            return false;
          });
      } else {
        if (this.countTakePhoto == 0) {
          this.getWebCamDeviceList();
        }
      }
    },
    /* capture photo */
    getWebCamDeviceList: function () {
      let $this = this;
      const constraints = (window.constraints = {
        audio: false,
        video: {
          width: 350,
          height: 350,
          facingMode: this.cameraType
        }
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          let cam_stream;
          cam_stream = stream;
          let live_video;
          live_video = document.createElement("video");
          live_video.srcObject = stream;
          live_video.setAttribute("id", "camera_stream");
          live_video.setAttribute("width", 350);
          live_video.setAttribute("height", 350);
          live_video.setAttribute("autoplay", true);
          live_video.setAttribute("muted", "");
          live_video.setAttribute("playsinline", "");
          $this.$refs.cameraView.appendChild(live_video);

          $this.formAdd.face_image = null;

          $this.capturedPhoto = null;

          $this.showTakePhoto = true;
          $this.showUploadPhoto = false;

          $this.countTakePhoto++;
        })
        .catch((error) => {
          console.log(error);
          alert(
            "Your connection is not secured. Please contact your system administrator."
          );
          this.$bvModal.hide("form-add-visitor-image");
          this.countTakePhoto = 0;
        });
    },
    takePhoto: async function () {
      let $this = this;
      let canvas = document.createElement("canvas");
      let video = document.getElementById("camera_stream");

      canvas.width = video.videoWidth * 0.8;
      canvas.height = video.videoHeight * 0.8;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);

      let base64Image = canvas.toDataURL("image/jpeg");

      let base64Image2 = await this.scCompressBase64Image(base64Image);

      let base64str = base64Image2.substring(base64Image2.indexOf(",") + 1);

      $this.capturedPhoto = base64str;
      $this.formAdd.face_image = $this.capturedPhoto;

      if (
        $this.$parent.$parent.$parent.selectedVisitorObj != null &&
        $this.isAddImage
      ) {
        $this.$parent.$parent.$parent.selectedVisitorObj.face_image =
          $this.capturedPhoto;
      }

      if ($this.isVisitor) {
        $this.$refs.photoView.appendChild(canvas);
      }
    },
    retakePhoto: function () {
      let $this = this;

      $this.capturedPhoto = null;

      if ($this.isVisitor) {
        $this.removePhotoView();
      }
    },
    doneTakePhoto: function () {
      let $this = this;
      $this.countTakePhoto = 0;

      this.stopCamera();
      this.removeCameraStream();

      this.$bvModal.hide("form-add-visitor-image");
    },
    stopCamera: function () {
      let $this = this;
      let tracks = null;

      if ($this.$refs?.cameraView) {
        if ($this.$refs?.cameraView?.lastChild) {
          tracks = $this.$refs?.cameraView?.lastChild?.srcObject?.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
        }
      }

      this.countTakePhoto = 0;
      this.showTakePhoto = false;
    },
    removeCameraStream: function () {
      let $this = this;
      if ($this.$refs.cameraView != null) {
        while ($this.$refs.cameraView.hasChildNodes()) {
          $this.$refs.cameraView.removeChild($this.$refs.cameraView.lastChild);
        }
      }
    },

    onSubmitUploadPictureForm() {
      let $this = this;

      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visitor/?visitor_id=" +
        $this.$parent.$parent.$parent.selectedVisitorObj.id;
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.$parent.$parent.$parent.selectedVisitorObj)
        .then((data) => {
          // update visitor to store
          let updateVisitor;

          outer_loop: for (let key in data.result) {
            if (data.result[key] == null) {
              updateVisitor = data.result[key];

              if (updateVisitor == null) {
                $this.stopCamera();
                $this.removeCameraStream();

                this.$parent.$parent.$parent.$bvModal.hide(
                  "form-add-visitor-image-2"
                );

                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Updated visitor successfully",
                  message_type: "success"
                });
              } else {
                if (data.detail != undefined) {
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: data.detail,
                    message_type: "danger"
                  });
                }
              }
              break outer_loop;
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    checkingExistingVisitor(formAdd, pdpa_setting) {
      let isExist = false;
      let API_URL = this.getAPIServerURL + "/api/visitorservice/visitor/all/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then(async (data) => {
          let validUrl = "";
          let result = data.result;
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;
                this.busy = false;
                this.search = false;
                break outer_loop;
              }
            }
          }
          if (result[validUrl] != undefined) {
            for (let key2 in result[validUrl].visitors) {
              //get visitor info
              if (pdpa_setting == "save_partial") {
                if (
                  formAdd.custom_id == result[validUrl].visitors[key2].custom_id
                ) {
                  isExist = true;
                  return isExist;
                } else {
                  isExist = false;
                  return isExist;
                }
              } else {
                if (formAdd.passport_number != null) {
                  if (
                    formAdd.passport_number ==
                    result[validUrl].visitors[key2].passport_number
                  ) {
                    isExist = true;
                    return isExist;
                  } else {
                    isExist = false;
                    return isExist;
                  }
                } else {
                  if (
                    formAdd.passport_number ==
                    result[validUrl].visitors[key2].passport_number
                  ) {
                    isExist = true;
                    return isExist;
                  } else {
                    isExist = false;
                    return isExist;
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          this.busy = false;
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },

    /* validation */
    validateCompany() {
      let result;
      if (this.$v.companyVisitor != null) {
        const { $dirty, $error } = this.$v.companyVisitor;
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validateContactNumber() {
      let result;
      if (this.contactNumber != null) {
        this.contactNumber = this.contactNumber.replace(/-/g, "");
      }

      if (this.$v.contactNumber != null) {
        const { $dirty, $error } = this.$v.contactNumber;
        result = $dirty ? !$error : null;
        this.result = result;
        return result;
      }
    },
    validateCarPlateNo() {
      let result;
      if (this.$v.carPlateNo != null) {
        const { $dirty, $error } = this.$v.carPlateNo;
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validateFormAdd(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.formAdd[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    onSubmitValidationVisitorForm() {
      let $this = this;
      let result = null;
      let user = this.isVisitor
        ? "visitor"
        : this.isRegister && this.parentUrl === "create-visit"
        ? "operator"
        : this.isRegister && this.parentUrl === "kiosk2"
        ? "kiosk"
        : "public";

      let idNumber =
        this.selectedIDType === "passport_number"
          ? this.$v.formAdd.passport_number.$anyError
          : this.$v.formAdd.nric_number.$anyError;

      let isCarPlateNoRequired =
        this.isRegister && this.parentUrl === "kiosk2"
          ? this.getPreference.additional_metadata
              .vms_credential_kiosk_license_plate_recognition
          : this.isPublic
          ? this.public_preference.additional_metadata
              .vms_credential_operator_license_plate_recognition
          : this.getPreference.additional_metadata
              .vms_credential_operator_license_plate_recognition;

      this.isFingerprintRequired =
        this.isRegister && this.parentUrl === "kiosk2"
          ? this.getPreference.additional_metadata
              .vms_credential_kiosk_fingerprint
          : (this.isRegister && this.parentUrl === "create-visit") ||
            this.isVisitor
          ? this.getPreference.additional_metadata
              .vms_credential_operator_fingerprint
          : false;

      $this.$v.formAdd.$touch();
      $this.$v.contactNumber.$touch();
      $this.$v.companyVisitor.$touch();
      $this.$v.carPlateNo.$touch();

      result =
        (this.$v.formAdd.$anyError && idNumber) ||
        this.$v.contactNumber.$anyError ||
        this.$v.companyVisitor.$anyError ||
        (this.$v.carPlateNo.$anyError && isCarPlateNoRequired) ||
        (this.uploadImageRequired() && this.formAdd.face_image == null) ||
        (!this.isPublic &&
          this.isFingerprintRequired &&
          !this.fingerprint1 &&
          !this.fingerprint2)
          ? false
          : true;

      return result;
    },

    // add visitor
    onSubmitAddForm: function (e) {
      if (e != undefined) {
        e.preventDefault();
      }
      let $this = this;
      let validForm = this.onSubmitValidationVisitorForm();
      if (validForm) {
        let pdpa_setting = this.isPublic
          ? this.public_preference.additional_metadata.vms_pdpa_setting
          : this.getPreference.additional_metadata.vms_pdpa_setting;
        let idNumber =
          this.selectedIDType === "passport_number"
            ? this.formAdd.passport_number
            : this.formAdd.nric_number;
        this.formAdd.custom_id = this.isPublic
          ? pdpa_setting === "save_partial"
            ? this.$parent.getCustomId(this.formAdd)
            : null
          : pdpa_setting === "save_partial"
          ? this.$parent.$parent.$parent.getCustomId(this.formAdd)
          : null;
        this.formAdd.passport_number =
          this.formAdd.passport_number != null
            ? this.isPublic
              ? pdpa_setting === "save_partial"
                ? this.$parent.getPartial(this.formAdd.passport_number)
                : this.formAdd.passport_number
              : pdpa_setting === "save_partial"
              ? this.$parent.$parent.$parent.getPartial(
                  this.formAdd.passport_number
                )
              : this.formAdd.passport_number
            : null;
        this.formAdd.nric_number =
          this.formAdd.nric_number != null
            ? this.isPublic
              ? pdpa_setting == "save_partial"
                ? this.$parent.getPartial(this.formAdd.nric_number)
                : this.formAdd.nric_number
              : pdpa_setting == "save_partial"
              ? this.$parent.$parent.$parent.getPartial(
                  this.formAdd.nric_number
                )
              : this.formAdd.nric_number
            : null;
        let country_code = $(".country-selector__input").val();
        this.formAdd.contact_number = country_code + this.contactNumber;
        this.formAdd.profile.car_plate_no =
          this.carPlateNo != null
            ? this.carPlateNo
            : this.formAdd.profile.car_plate_no != null &&
              this.formAdd.profile.car_plate_no != ""
            ? this.formAdd.profile.car_plate_no
            : null;
        this.formAdd.profile.company = $this.companyVisitor;
        this.formAdd.profile.gender = $this.selectedVisitorGender;
        $this.formAdd.face_image =
          $this.formAdd.face_image != null
            ? $this.formAdd.face_image.replace(
                /^data:image\/[a-z]+;base64,/,
                ""
              )
            : null;
        let template1 = null;
        let template2 = null;
        let text = "Please verify your latest information : ";
        let h = this.$createElement;
        let message = null;

        if (
          ((this.parentUrl == "create-visit" ||
            this.isVisitor ||
            !this.isPublic) &&
            this.getPreference.additional_metadata
              .vms_credential_operator_fingerprint) ||
          (this.parentUrl == "kiosk2" &&
            this.getPreference.additional_metadata
              .vms_credential_kiosk_fingerprint)
        ) {
          /* template1 = this.formAdd?.profile?.fingerprint?.template1
            ? "fingerprint template 1"
            : "-";
          template2 = this.formAdd?.profile?.fingerprint?.template2
            ? "fingerprint template 2"
            : "-"; */
          if (
            this.formAdd?.profile?.fingerprint?.template1 &&
            !this.formAdd?.profile?.fingerprint?.template2
          ) {
            message = h("div", [
              h("h1", text),
              h("p"),
              h("p", [
                h("strong", "Full name : "),
                h("span", this.formAdd.name)
              ]),
              h("p", [
                h("strong", "Company : "),
                h("span", this.formAdd.profile.company)
              ]),
              h("p", [
                h("strong", "Gender : "),
                h("span", this.formAdd.profile.gender)
              ]),
              h("p", [h("strong", "Email : "), h("span", this.formAdd.email)]),
              h("p", [
                h("strong", "Car Plate No. : "),
                h("span", this.formAdd?.profile?.car_plate_no ?? "-")
              ]),
              h("p", [
                h("strong", "Contact Number : "),
                h("span", this.formAdd.contact_number)
              ]),
              h("p", [
                h("strong", "NRID / Passport Number : "),
                h("span", idNumber)
              ]),
              h("p", [
                h("strong", "Fingerprint Template 1 : "),
                h("span", "fingerprint template 1")
              ])
            ]);
          } else if (
            !this.formAdd?.profile?.fingerprint?.template1 &&
            this.formAdd?.profile?.fingerprint?.template2
          ) {
            message = h("div", [
              h("h1", text),
              h("p"),
              h("p", [
                h("strong", "Full name : "),
                h("span", this.formAdd.name)
              ]),
              h("p", [
                h("strong", "Company : "),
                h("span", this.formAdd.profile.company)
              ]),
              h("p", [
                h("strong", "Gender : "),
                h("span", this.formAdd.profile.gender)
              ]),
              h("p", [h("strong", "Email : "), h("span", this.formAdd.email)]),
              h("p", [
                h("strong", "Car Plate No. : "),
                h("span", this.formAdd?.profile?.car_plate_no ?? "-")
              ]),
              h("p", [
                h("strong", "Contact Number : "),
                h("span", this.formAdd.contact_number)
              ]),
              h("p", [
                h("strong", "NRID / Passport Number : "),
                h("span", idNumber)
              ]),
              h("p", [
                h("strong", "Fingerprint Template 2 : "),
                h("span", "fingerprint template 2")
              ])
            ]);
          } else {
            message = h("div", [
              h("h1", text),
              h("p"),
              h("p", [
                h("strong", "Full name : "),
                h("span", this.formAdd.name)
              ]),
              h("p", [
                h("strong", "Company : "),
                h("span", this.formAdd.profile.company)
              ]),
              h("p", [
                h("strong", "Gender : "),
                h("span", this.formAdd.profile.gender)
              ]),
              h("p", [h("strong", "Email : "), h("span", this.formAdd.email)]),
              h("p", [
                h("strong", "Car Plate No. : "),
                h("span", this.formAdd?.profile?.car_plate_no ?? "-")
              ]),
              h("p", [
                h("strong", "Contact Number : "),
                h("span", this.formAdd.contact_number)
              ]),
              h("p", [
                h("strong", "NRID / Passport Number : "),
                h("span", idNumber)
              ]),
              h("p", [
                h("strong", "Fingerprint Template 1 : "),
                h("span", "fingerprint template 1")
              ]),
              h("p", [
                h("strong", "Fingerprint Template 2 : "),
                h("span", "fingerprint template 2")
              ])
            ]);
          }
        } else {
          message = h("div", [
            h("h1", text),
            h("p"),
            h("p", [h("strong", "Full name : "), h("span", this.formAdd.name)]),
            h("p", [
              h("strong", "Company : "),
              h("span", this.formAdd.profile.company)
            ]),
            h("p", [
              h("strong", "Gender : "),
              h("span", this.formAdd.profile.gender)
            ]),
            h("p", [h("strong", "Email : "), h("span", this.formAdd.email)]),
            h("p", [
              h("strong", "Car Plate No. : "),
              h("span", this.formAdd?.profile?.car_plate_no ?? "-")
            ]),
            h("p", [
              h("strong", "Contact Number : "),
              h("span", this.formAdd.contact_number)
            ]),
            h("p", [
              h("strong", "NRID / Passport Number : "),
              h("span", idNumber)
            ])
          ]);
        }

        console.log("formAdd", this.formAdd);
        this.$bvModal
          .msgBoxConfirm([message], {
            centered: true
          })
          .then((value) => {
            if (value) {
              let result = null;
              let validUrl = null;
              let addVisitor = null;
              let API_URL = this.isPublic
                ? this.getAPIServerURL + "/public/vms/register/"
                : this.getAPIServerURL + "/api/visitorservice/visitor/";

              const client = $this.$root.getAjaxFetchClient();
              client
                .postRequest(API_URL, $this.formAdd)
                .then((data) => {
                  if (data.detail != undefined) {
                    $this.formAddErrorMessage = data.detail;
                  } else {
                    result = data.result;
                    outer_loop: for (let key in result) {
                      validUrl = key;
                      break outer_loop;
                    }
                    if (
                      typeof result[validUrl] === "object" &&
                      result[validUrl] !== null
                    ) {
                      addVisitor = result[validUrl];
                      $this.$store.dispatch("visitor/addVisitor", addVisitor);
                      $this.$store.dispatch("session/addGlobalAlertMessage", {
                        message_text:
                          "Visitor " +
                          addVisitor.name +
                          " has been added successfully",
                        message_type: "success"
                      });

                      // public page
                      if ($this.isPublic) {
                        $this.$parent.selectedVisitorObj = addVisitor;
                        $this.$parent.visitors.push(addVisitor);
                        $this.$parent.submitAndCreateVisit();
                      }

                      // visitor page
                      if ($this.isVisitor) {
                        this.$nextTick(() => {
                          this.$v.$reset();
                          if (
                            this.$parent.$parent.$parent.searchForm.keyword !=
                            undefined
                          ) {
                            this.$parent.$parent.$parent.getSearchVisitor();
                          } else {
                            this.$parent.$parent.$parent.getVisitorsList();
                          }
                        });
                      }

                      //operator and kiosk page
                      if ($this.isRegister) {
                        this.$v.$reset();
                        this.$parent.$parent.$parent.selectedVisitorObj =
                          addVisitor;
                        $this.$parent.$parent.$parent.visitors = [addVisitor];
                        $this.referenceParent.multiselect_visitor.$refs.list.disabled = true;
                        $this.referenceParent.multiselect_visitor.$refs.search.disabled = true;
                      }

                      $this.formAdd.name = null;
                      $this.formAdd.email = null;
                      $this.formAdd.face_image = null;
                      $this.formAdd.custom_id = null;
                      $this.formAdd.nric_number = null;
                      $this.formAdd.contact_number = null;
                      $this.formAdd.passport_number = null;
                      $this.formAdd.profile.gender = null;
                      $this.formAdd.profile.company = null;
                      $this.formAdd.profile.car_plate_no = null;
                      $this.formAdd.profile.fingerprint.template1 = null;
                      $this.formAdd.profile.fingerprint.template2 = null;
                      $this.selectedIDName = null;
                      $this.companyVisitor = null;
                      $this.contactNumber = null;
                      $this.formAddErrorMessage = null;
                      $this.showTakePhoto = false;
                      $this.showUploadPhoto = false;
                      $this.countTakePhoto = 0;
                      $this.stopCamera();
                      $this.referenceParent.formAddVisitor.hide();
                    } else {
                      const obj = JSON.parse(result[validUrl]);
                      $this.formAddErrorMessage = obj;
                    }
                  }
                })
                .catch((err) => {
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: err.detail,
                    message_type: "danger"
                  });
                  // if (err.status == 401) {
                  //   $this.$store.dispatch("session/logoutSession");
                  // }
                });
            }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
          });
      } else {
        if (
          this.isVisitor &&
          this.uploadImageRequired() &&
          $this.formAdd.face_image == null
        ) {
          $this.$bvModal.msgBoxOk(
            "Please upload or take a picture for profile picture.",
            {
              centered: true
            }
          );
          return false;
        } else {
          if (this.uploadImageRequired() && $this.formAdd.face_image == null) {
            $this.$bvModal.msgBoxOk(
              /* "Please upload or take a picture for profile picture.", */
              "Please take a picture for profile picture.",
              {
                centered: true
              }
            );
            return false;
          }
        }

        /* $this.$bvModal.msgBoxOk("Form not completed!", {
          centered: true
        });
        return false; */
      }
    },

    // setting image required
    uploadImageRequired() {
      let result = null;
      let user = this.isVisitor
        ? "visitor"
        : this.isPublic
        ? "public"
        : this.isRegister && this.parentUrl == "create-visit"
        ? "operator"
        : this.isRegister && this.parentUrl == "kiosk2"
        ? "kiosk"
        : null;

      switch (user) {
        case "visitor":
          result =
            this.getPreference?.additional_metadata
              ?.vms_registration_visitor_profile_picture_required ||
            this.getPreference?.additional_metadata
              ?.vms_credential_operator_face_recognition
              ? true
              : false;
          break;
        case "operator":
          result =
            this.getPreference.additional_metadata
              .vms_registration_visitor_profile_picture_required ||
            this.getPreference.additional_metadata
              .vms_credential_operator_face_recognition
              ? true
              : false;
          break;
        case "kiosk":
          result =
            this.getPreference.additional_metadata
              .vms_registration_visitor_profile_picture_required ||
            this.getPreference.additional_metadata
              .vms_credential_kiosk_face_recognition
              ? true
              : false;
          break;
        default:
          return (
            this.public_preference.additional_metadata
              ?.vms_registration_visitor_profile_picture_required ||
            this.public_preference.additional_metadata
              ?.vms_credential_operator_face_recognition
          );
      }

      return result;
    },

    // readIcCardInfo
    readIcCardInfo() {
      this.isDisabled = true;
      this.scanAddErrorMessage = null;

      let $this = this;
      let url = "http://127.0.0.1:28080/api/card/info/";
      let req = new Request(url);
      fetch(req, {
        method: "GET"
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("result", data);
          $this.isDisabled = false;
          $this.selectedIDName = data.DocumentNumber.replace(/-/g, "");
          if (
            $this.selectedIDType === "nric_number" &&
            (data.DocumentType === "Identity Card" ||
              data.DocumentType === "MyKad")
          ) {
            $this.formAdd.nric_number = $this.selectedIDName;
            $this.formAdd.name = data.Name;
            if (data.Gender == "PEREMPUAN" || data.Gender == "Female") {
              $this.selectedVisitorGender = "female";
              $this.formAdd.profile.gender = $this.selectedVisitorGender;
            } else {
              $this.selectedVisitorGender = "male";
              $this.formAdd.profile.gender = $this.selectedVisitorGender;
            }

            if (data.Photo == "") {
              $this.formAdd.face_image = null;
            } else {
              $this.formAdd.face_image = data.Photo;
            }

            let message_text = "Successfully scan the ic.";
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: message_text,
              message_type: "success"
            });
          } else if (
            $this.selectedIDType === "passport_number" &&
            data.DocumentType !== "Identity Card" &&
            data.DocumentType !== "MyKad"
          ) {
            $this.formAdd.passport_number = $this.selectedIDName;
            $this.formAdd.name = data.Name;
            if (data.Gender == "PEREMPUAN" || data.Gender == "Female") {
              $this.selectedVisitorGender = "female";
              $this.formAdd.profile.gender = $this.selectedVisitorGender;
            } else {
              $this.selectedVisitorGender = "male";
              $this.formAdd.profile.gender = $this.selectedVisitorGender;
            }

            if (data.Photo == "") {
              $this.formAdd.face_image = null;
            } else {
              $this.formAdd.face_image = data.Photo;
            }

            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Successfully scan the passport",
              message_type: "success"
            });
          } else {
            $this.selectedIDName = null;
            $this.selectedVisitorGender = null;

            $this.formAdd.name = null;
            $this.formAdd.profile.gender = $this.selectedVisitorGender;
            $this.formAdd.face_image = null;

            let message_text = "Please choose ID type correctly.";
            $this.scanAddErrorMessage = message_text;
          }

          this.showUploadPhoto = true;
        })
        .catch((err) => {
          this.isDisabled = false;
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err,
            message_type: "danger"
          });
        });
    },
    // readFingerprint
    readFingerprint() {
      /* let data = {
        Template1:
          "45201114974A534217C0A0AB8A1EC1404E0626C161A7070C01F1B08A0E44110507180570AF881F46104B8432C6E19F043EC7319E8D23C7D0A4891D09B04F06144A50030A1E0A51AA07278AA1A5883DCB909F0235CBA142871B8C61AF890F0CA1620F184D81030C1B0D91508C244E21A485374F01998421CF50A38833CFF13F8536513094831711611F1A11D1F127962012209A83195270392A2952E09E880C92F02B0D281330468AFFFF12223333FFFFFFFF1112222333FFFFFF00112222334FFFFFE000122223344FFFFEE001222334444FFDDEE012223344444FDDEE011223334444FDDEE011223333344FDDEE0112233343444DDEEE112233344444DDDEE112233444444CCDDE012233444444CCCDD012233444444BCCCD012333444444BBBCC012334444445AAABBD13444445555AAABBC13455555555A9AAAA4445555555599999754444455556F888875443344555600000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
        Template2:
          "451F0E14924A53421082E0000F19C3D149032883F19A841E0421A28633055099833345B04084100620B10F1C06B0A4872B86C09A861D87E044851288C1AE921E4A513B072DCAF13B07110B7100B1030B80220F164C0098092F4C919584098D61280D294D70380B0D4DC02E8B0C0E2082880B4E703188230E81310733CF808E0E1BCFC18F0B06D0E1370932D1308B8C1E91718E8E0B518190071851C19308299281938CFFFFF23344FFFFFFFF1234444FFFFFFF13344444FFFFF0134444455FFDE0134444445FDEE0133344445FDDE0133344445FDDE0133344444FCDE01334444445CCDE1344444445BCCE0445555555ABBCE55555555F9AAA966666555F9999766666555F8887766776666F7766667777777F7666666677777F766555566667FF766554455544FFF6555444433FFF000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"
      };
      this.fingerprint1 = data?.Template1 ? true : false;
      this.fingerprint2 = data?.Template2 ? true : false;
      this.formAdd.profile.fingerprint.template1 = data?.Template1 ?? null;
      this.formAdd.profile.fingerprint.template2 = data?.Template2 ?? null;
      this.isFingerprintRequired = false; */

      let $this = this;
      let url = "";
      url = "http://127.0.0.1:28080/api/fingerprint/info/";
      let req = new Request(url);
      fetch(req, {
        method: "GET"
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("result", data);
          this.fingerprint1 = data?.Template1 ? true : false;
          this.fingerprint2 = data?.Template2 ? true : false;
          this.formAdd.profile.fingerprint.template1 = data?.Template1 ?? null;
          this.formAdd.profile.fingerprint.template2 = data?.Template2 ?? null;

          this.isFingerprintRequired = false;
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err,
            message_type: "danger"
          });
        });
    },
    //delete fingerprint
    deleteFingerprintHandler(template) {
      this.$bvModal
        .msgBoxConfirm("Are you sure to remove fingerprint " + template + "?", {
          okTitle: "Yes",
          cancelTitle: "No",
          centered: true
        })
        //return false;
        .then(async (value) => {
          if (value) {
            template == "template1"
              ? ((this.fingerprint1 = false),
                (this.formAdd.profile.fingerprint.template1 = null))
              : ((this.fingerprint2 = false),
                (this.formAdd.profile.fingerprint.template2 = null));
          }
        })
        .catch((err) => {
          // An error occurred
          return false;
        });
    }
  }
};
</script>

<style>
.invalid-fingerprint-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #d53e4c;
}
</style>
