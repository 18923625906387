<template>
  <div>
    <b-form @submit="onSubmitUpdateForm">
      <b-form-group
        v-if="uploadImageRequired()"
        id="input-group-face_image"
        label="Profile Picture:"
        label-for="input-face_image"
      >
        <b-form-file
          id="input-face_image"
          accept="image/*"
          @change="onFileChange(formUpdate, $event)"
          style="display: none"
        ></b-form-file>

        <b-button
          class="ml-2 mr-1"
          size="md"
          variant="secondary"
          v-b-tooltip.hover
          title="Upload Photo"
          @click="uploadPhoto"
        >
          <i class="fas fa-file-upload"></i>
        </b-button>
        <b-button
          class="ml-2 mr-1"
          size="md"
          variant="warning"
          v-b-tooltip.hover
          title="Take Photo"
          @click="getCapture"
        >
          <i class="fas fa-camera"></i>
        </b-button>
        <div
          class="row justify-content-center mt-2"
          v-show="updateShowTakePhoto == true"
        >
          <div
            id="live_video_container"
            ref="cameraView"
            v-show="updateCapturedPhoto == null"
          ></div>
          <div
            id="photo_container"
            ref="photoView"
            v-show="updateCapturedPhoto != null"
          ></div>
        </div>
        <div
          class="row justify-content-center mt-2"
          v-show="updateShowUploadPhoto == true"
        >
          <div class="visitor-card-face-image">
            <img :src="showImage(formUpdate.face_image)" fluid />
          </div>
        </div>
        <div
          class="row justify-content-center mt-2"
          v-if="updateShowTakePhoto == true"
        >
          *Note : Please uncover and position your face in front of the camera.
        </div>
        <div
          class="row justify-content-center mt-2"
          v-if="updateShowTakePhoto == true"
        >
          <b-button
            variant="secondary"
            @click="retakePhoto"
            v-if="updateCapturedPhoto != null"
            >Retake Photo
          </b-button>
          <b-button
            variant="primary"
            @click="takePhoto"
            v-if="updateCapturedPhoto == null"
            >Take Photo
          </b-button>
          <b-button
            class="ml-2 mr-1"
            size="md"
            variant="warning"
            v-b-tooltip.hover
            v-if="updateCapturedPhoto == null"
            @click="getCapture"
          >
            <i class="fa-solid fa-camera-rotate"></i>
          </b-button>
        </div>
        <div
          class="row justify-content-center mt-2"
          v-if="updateShowUploadPhoto == true"
        >
          <b-button variant="danger" @click="reUploadPhoto"
            >Remove Photo
          </b-button>
        </div>
      </b-form-group>

      <b-form-group
        id="input-group-name"
        label="Full name:"
        label-for="input-name"
      >
        <b-form-input
          id="input-name"
          v-model="$v.formUpdate.name.$model"
          placeholder="Enter name"
          :state="validateFormUpdate('name')"
          aria-describedby="formUpdate.name_feedback"
        ></b-form-input>
        <b-form-invalid-feedback
          class="invalid-feedback"
          id="formUpdate.name_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-company"
        label="Company:"
        label-for="input-company"
      >
        <b-form-input
          v-model="$v.companyVisitor.$model"
          placeholder="Enter company"
          :state="validateCompany()"
          aria-describedby="companyVisitor_feedback"
        ></b-form-input>
        <b-form-invalid-feedback
          class="invalid-feedback"
          id="companyVisitor_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-gender"
        label="Gender"
        label-for="input-gender"
      >
        <b-form-select
          id="input-gender"
          v-model="selectedVisitorGender"
          :options="visitorGenderOptions"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-email"
        label="Email:"
        label-for="input-email"
      >
        <b-form-input
          id="input-email"
          v-model="formUpdate.email"
          placeholder="example@email.com"
          :state="validateFormUpdate('email')"
          aria-describedby="formUpdate.email_feedback"
          @keyup="handleLowercase"
        ></b-form-input>
        <b-form-invalid-feedback
          v-if="!$v.formUpdate.email.required"
          class="invalid-feedback"
          id="formUpdate.email_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
        <b-form-invalid-feedback
          v-if="!$v.formUpdate.email.email"
          class="invalid-feedback"
          id="formUpdate.email_feedback"
        >
          Needs to be a valid email. Example : example@email.com
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="
          getPreference.additional_metadata
            .vms_credential_operator_license_plate_recognition
        "
        id="input-group-car_plate"
        label="Car Plate No :"
        label-for="input-car_plate"
      >
        <b-form-input
          id="input-car_plate_no"
          v-model="$v.carPlateNo.$model"
          placeholder="Enter Car Plate No: ABC123"
          :state="validateCarPlateNo()"
          aria-describedby="formUpdate.carPlateNo_feedback"
        ></b-form-input>
        NOTE : Key in "-" if no license plate number.
        <b-form-invalid-feedback
          class="invalid-feedback"
          id="formUpdate.carPlateNo_feedback"
        >
          This field is required.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="
          !getPreference.additional_metadata
            .vms_credential_operator_license_plate_recognition
        "
        id="input-group-car_plate"
        label="Car Plate No :"
        label-for="input-car_plate"
      >
        <b-form-input
          v-model="formUpdate.profile.car_plate_no"
          placeholder="Enter Car Plate No: ABC123"
        ></b-form-input>
        NOTE : Key in "-" if no license plate number.
      </b-form-group>

      <b-form-group
        id="input-group-contact_number"
        label="Contact Number:"
        label-for="input-contact_number"
      >
        <VuePhoneNumberInput
          v-bind="vuePhone.props"
          v-model="$v.contact_number.$model"
          @input="handleRegexPhoneNumber"
          :state="validateContactNumber()"
          aria-describedby="formUpdate_contact_number_feedback"
        />
        <div
          v-if="result == false"
          class="invalid-contact-number-feedback"
          id="formUpdate_contact_number_feedback"
        >
          This field is required.
        </div>
      </b-form-group>

      <b-form-group
        id="input-group-national-id"
        label="National ID Type"
        label-for="input-national-id"
      >
        <b-row no-gutters>
          <b-col sm="5">
            <b-form-select
              id="select-national-id"
              v-model="selectedIDType"
              :options="nationalIDOptions"
              @change="handleUpdateChangeID"
              required
            ></b-form-select>
          </b-col>
          <b-col sm="7">
            <!-- :placeholder="handlePlaceholder(selectedIDType)" -->
            <b-form-input
              id="input-national-id"
              class="mb-1"
              v-model="selectedIDName"
              @input="handleInput(selectedIDType, selectedIDName)"
              placeholder="Please input......."
              :state="validateFormUpdate(selectedIDType)"
              :aria-describedby="selectedIDType"
            ></b-form-input>
            <b-form-invalid-feedback
              class="invalid-feedback"
              :id="selectedIDType"
            >
              This field is required.
            </b-form-invalid-feedback>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <!-- a visitor's NRID (MyKad) or passport number -->
              <b-button
                class="reader_button"
                v-b-tooltip.hover
                title="Click this button to scan"
                variant="secondary"
                :disabled="isDisabled"
                @click="readIcCardInfo"
                v-show="
                  getPreference.additional_metadata.vms_enable_biometric_scanner
                "
                >Scan
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <div
            class="d-flex justify-content-start text-danger"
            v-if="scanUpdateErrorMessage != null"
          >
            {{ scanUpdateErrorMessage }}
          </div>
        </b-row>
      </b-form-group>

      <b-form-group
        id="input-group-fingerprint"
        label="Fingerprint:"
        label-for="input-fingerprint"
        v-if="
          getPreference.additional_metadata.vms_credential_operator_fingerprint
        "
      >
        <table>
          <tr>
            <td>
              <b-button
                v-b-tooltip.hover
                title="Click this button to scan finger"
                variant="primary"
                @click="readFingerprint"
                >Scan Finger
              </b-button>
            </td>
            <td>
              <span v-if="fingerprint1">
                fingerprint template 1
                <i
                  class="far fa-window-close ml-3"
                  @click="deleteFingerprintHandler('template1')"
                ></i></span
              ><br />
              <span v-if="fingerprint2">
                fingerprint template 2
                <i
                  class="far fa-window-close ml-3"
                  @click="deleteFingerprintHandler('template2')"
                ></i
              ></span>
            </td>
            <td>
              <span v-if="!fingerprint1 && !fingerprint2"> No fingerprint</span>
            </td>
          </tr>
        </table>
        <div
          v-if="
            isFingerprintRequired &&
            formUpdate.profile.fingerprint.template1 == null &&
            formUpdate.profile.fingerprint.template2 == null
          "
          class="invalid-fingerprint-feedback"
          id="formAdd_fingerprint_feedback"
        >
          Finger print is required.
        </div>
      </b-form-group>

      <div class="text-danger" v-if="formUpdateErrorMessage">
        {{ formUpdateErrorMessage }}
      </div>
      <div class="text-right">
        <b-button type="submit" variant="primary">Submit</b-button>
      </div>
    </b-form>

    <!-- update image modal -->
    <!-- <b-modal
        id="form-update-visitor-image"
        centered
        hide-footer
        :no-close-on-backdrop="true"
        :hide-header-close="false"
        @close="doneTakePhoto('update')"
      >
        <div
          class="row justify-content-center mt-2"
          v-if="updateCapturedPhoto != null"
        >
          <div>
            <img :src="showImage(formUpdate.face_image)" fluid />
          </div>
        </div>

        <div
          class="row justify-content-center mt-2"
          v-show="updateCapturedPhoto == null"
        >
          <div id="live_video_container" ref="cameraView"></div>
        </div>

        <div class="d-flex justify-content-center" v-if="updateShowTakePhoto">
          <b-button
            class="my-2 mx-1"
            @click="retakePhoto"
            v-if="updateCapturedPhoto != null"
            >Retake Photo
          </b-button>
          <b-button
            class="my-2 mx-1"
            variant="primary"
            @click="takePhoto('update')"
            v-if="updateCapturedPhoto == null"
            >Take Photo
          </b-button>
          <b-button
            class="my-2 mx-1"
            size="md"
            variant="warning"
            v-b-tooltip.hover
            title="Front Camera or Rare Camera"
            @click="getCapture('update')"
          >
            <i class="fa-solid fa-camera-rotate"></i>
          </b-button>
        </div>
      </b-modal> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
  between,
  helpers
} from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import * as imageConversion from "image-conversion";

let convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

import jQuery from "jquery";

let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

export default {
  mixins: [validationMixin],
  components: {
    VuePhoneNumberInput
  },
  props: {
    updateData: {},
    getPreference: {},
    referenceParent: {},
    getCountryCode: {},

    idUpdate: {
      type: String
    },
    idType: {
      type: String
    },
    idName: {
      type: String
    },
    showPhoto: {
      type: Boolean
    }
  },
  data: function () {
    return {
      nationalIDOptions: [],
      visitorGenderOptions: [
        { value: "male", text: "Male" },
        { value: "female", text: "Female" }
      ],
      vuePhone: {
        phone: "",
        props: {
          clearable: true,
          defaultCountryCode: "MY",
          preferredCountries: ["SG", "ID"],
          noExample: true,
          translations: {
            countrySelectorLabel: "",
            countrySelectorError: "Error",
            example: "Example:",
            phoneNumberLabel: "Example: 188888888"
            //countrySelectorLabel: "Country code",
          }
          // fetchCountry: true,
        }
      },

      selectedIDType: this.idType,
      selectedIDName: this.idName,
      contact_number: this.updateData.contact_number,
      carPlateNo: this.updateData.profile.car_plate_no,
      selectedVisitorGender: this.updateData.profile.gender,
      companyVisitor: this.updateData.profile.company,

      formUpdateID: this.idUpdate,
      formUpdate: this.updateData,
      formUpdateErrorMessage: null,

      updateShowTakePhoto: false,
      updateShowUploadPhoto: this.showPhoto,

      updateCapturedPhoto: null,
      scanUpdateErrorMessage: null,

      isDisabled: false,
      countTakePhoto: 0,

      result: null,
      cameraType: null,

      fingerprint1: false,
      fingerprint2: false,
      scanFingerprint1: null,
      scanFingerprint2: null,
      isFingerprintRequired: false,

      istriggerValidation: false,

      defaultImage: require("@/assets/global/images/icons/profile_sample.jpg")
    };
  },
  computed: {
    ...mapGetters({
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  validations: {
    contact_number: { required },
    companyVisitor: { required },
    carPlateNo: { required },

    formUpdate: {
      name: { required },
      email: { required, email },
      passport_number: { required },
      nric_number: { required } //, regex_nric }
    }
  },
  mounted: function () {
    this.getNationalID();
    this.getFingerprint();
  },
  methods: {
    getNationalID() {
      //this.getCountryCode = "IDN";
      let country_code = null;
      for (let key in this.getPreference.additional_metadata.national_id_type) {
        if (this.getCountryCode == key) {
          country_code = key;
        }
      }

      for (let key2 in this.getPreference.additional_metadata.national_id_type[
        country_code
      ]) {
        if (
          this.getPreference.additional_metadata.national_id_type[country_code][
            key2
          ] == "Passport"
        ) {
          this.nationalIDOptions.push({
            value: "passport_number",
            text: this.getPreference.additional_metadata.national_id_type[
              country_code
            ][key2]
          });
        } else {
          this.nationalIDOptions.push({
            value: "nric_number",
            text: this.getPreference.additional_metadata.national_id_type[
              country_code
            ][key2]
          });
        }
      }
    },
    getFingerprint() {
      this.fingerprint1 = this.updateData?.profile?.fingerprint?.template1
        ? true
        : false;
      this.fingerprint2 = this.updateData?.profile?.fingerprint?.template2
        ? true
        : false;
    },
    //get image
    showImage: function (img) {
      let $this = this;
      return img == null || img == ""
        ? $this.defaultImage
        : `data:image/jpeg;base64,${img}`;
    },
    //file change
    onFileChange: function (item, e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(item, files[0]);
    },
    createImage: function (item, file) {
      let $this = this;
      let reader = new FileReader();

      reader.onload = async (e) => {
        let base64Image2 = await this.scCompressBase64Image(e.target.result);

        item.face_image = base64Image2.substring(base64Image2.indexOf(",") + 1);

        $this.updateShowUploadPhoto = true;
        $this.updateUploadedPhoto = item.face_image;
      };
      reader.readAsDataURL(file);
    },

    async scCompressBase64Image(base64Image) {
      const buffer = Buffer.from(
        base64Image.substring(base64Image.indexOf(",") + 1)
      );

      let base64Response = await fetch(base64Image);

      let blob = await base64Response.blob();

      let compressedBlob = await imageConversion.compressAccurately(blob, {
        size: 200, //The compressed image size is 100kb
        accuracy: 0.5, //the accuracy of image compression size,range 0.8-0.99,default 0.95;
        //this means if the picture size is set to 1000Kb and the
        //accuracy is 0.9, the image with the compression result
        //of 900Kb-1100Kb is considered acceptable;
        type: "image/jpeg",
        width: 350,
        height: 420,
        orientation: 2,
        scale: 0.8
      });

      let compressedBase64String = await convertBlobToBase64(compressedBlob);
      const buffer2 = Buffer.from(
        compressedBase64String.substring(
          compressedBase64String.indexOf(",") + 1
        )
      );

      return compressedBase64String;
    },

    //handle camera
    handleUpdateChangeID() {
      if (this.selectedIDType == "nric_number") {
        this.selectedIDName = this.updateData.nric_number;
      } else {
        this.selectedIDName = this.updateData.passport_number;
      }
    },
    handleRegexPhoneNumber() {
      if (this.contact_number != null) {
        this.contact_number = this.contact_number.replace(/-/g, "");
      }
      this.formUpdate.contact_number = this.contact_number;
    },
    handleInput(id, value) {
      if (id == "nric_number") {
        this.$v.formUpdate.nric_number.$model = value;
      } else {
        this.$v.formUpdate.passport_number.$model = value;
      }
    },
    handleCamera() {
      if (this.cameraType == "user") {
        this.cameraType = "environment";
        console.log("this.cameraType1", this.cameraType);

        this.stopCamera();
        this.removeCameraStream();
      } else if (this.cameraType == "environment") {
        this.cameraType = "user";
        console.log("this.cameraType2", this.cameraType);

        this.stopCamera();
        this.removeCameraStream();
      } else if (this.cameraType == null) {
        this.cameraType = "user";
        console.log("this.cameraType3", this.cameraType);
      }
    },
    handleLowercase() {
      if (this.formUpdate.email != null) {
        this.formUpdate.email = this.formUpdate.email.toLowerCase();
      }
    },

    async getCapture() {
      this.handleCamera();
      if (
        this.formUpdate.face_image != null &&
        this.updateShowUploadPhoto == true
      ) {
        this.$bvModal
          .msgBoxConfirm(
            "A profile picture is already uploaded, do you wish to remove the uploaded picture and take a new picture?",
            {
              okTitle: "Yes",
              cancelTitle: "No",
              centered: true
            }
          )
          //return false;
          .then(async (value) => {
            if (value) {
              if (this.countTakePhoto == 0) {
                this.getWebCamDeviceList();
              }
            }
          })
          .catch((err) => {
            // An error occurred
            return false;
          });
      } else {
        if (this.countTakePhoto == 0) {
          this.getWebCamDeviceList();
        }
      }
    },
    //get camera
    getWebCamDeviceList: function () {
      let $this = this;
      const constraints = (window.constraints = {
        audio: false,
        video: {
          width: 350,
          height: 350,
          facingMode: this.cameraType
        }
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((data) => {
          let cam_stream;
          let live_video;
          cam_stream = data;

          live_video = document.createElement("video");
          live_video.srcObject = data;
          live_video.setAttribute("id", "camera_stream");
          live_video.setAttribute("width", 350);
          live_video.setAttribute("height", 350);
          live_video.setAttribute("autoplay", true);
          live_video.setAttribute("muted", "");
          live_video.setAttribute("playsinline", "");

          $this.$refs.cameraView.appendChild(live_video);

          $this.updateCapturedPhoto = null;
          $this.formUpdate.face_image = null;

          $this.updateShowTakePhoto = true;
          $this.updateShowUploadPhoto = false;

          $this.countTakePhoto++;
        })
        .catch((error) => {
          console.log(error);
          alert(
            "Your connection is not secured. Please contact your system administrator."
          );
        });
    },
    takePhoto: async function () {
      let $this = this;
      let canvas = document.createElement("canvas");
      let video = document.getElementById("camera_stream");

      canvas.width = video.videoWidth * 0.8;
      canvas.height = video.videoHeight * 0.8;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);

      let base64Image = canvas.toDataURL("image/jpeg");

      let base64Image2 = await this.scCompressBase64Image(base64Image);

      let base64str = base64Image2.substring(base64Image2.indexOf(",") + 1);

      $this.updateCapturedPhoto = base64str;
      $this.formUpdate.face_image = $this.updateCapturedPhoto;

      $this.$refs.photoView.appendChild(canvas);
    },
    retakePhoto: function () {
      let $this = this;

      $this.removePhotoView();

      $this.updateCapturedPhoto = null;
    },
    /* doneTakePhoto: function () {
      let $this = this;

      this.stopCamera();
      this.removeCameraStream();

      this.countTakePhoto = 0;
      this.$bvModal.hide("form-update-visitor-image");
      
    }, */
    stopCamera: function () {
      let $this = this;
      let tracks = null;
      if ($this.$refs?.cameraView) {
        if ($this.$refs?.cameraView?.lastChild) {
          tracks = $this.$refs?.cameraView?.lastChild?.srcObject.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
        }
      }

      this.countTakePhoto = 0;
      this.updateShowTakePhoto = false;
    },

    //upload photo
    uploadPhoto: function () {
      let $this = this;

      $this.countTakePhoto = 0;
      $this.updateCapturedPhoto = null;

      $this.updateShowTakePhoto = false;

      $this.stopCamera();
      $this.removeCameraStream();
      $this.removePhotoView();

      if (document.getElementById("input-face_image").value != "") {
        document.getElementById("input-face_image").value = "";
      }

      document.getElementById("input-face_image").click();
    },
    reUploadPhoto: function () {
      let $this = this;

      $this.countTakePhoto = 0;
      $this.updateShowUploadPhoto = false;

      $this.updateUploadedPhoto = null;
      $this.formUpdate.face_image = null;
    },

    removeCameraStream: function () {
      let $this = this;
      if ($this.$refs.cameraView != null) {
        while ($this.$refs.cameraView.hasChildNodes()) {
          $this.$refs.cameraView.removeChild($this.$refs.cameraView.lastChild);
        }
      }
    },
    removePhotoView: function () {
      let $this = this;
      if ($this.$refs.photoView != null) {
        while ($this.$refs.photoView.hasChildNodes()) {
          $this.$refs.photoView.removeChild($this.$refs.photoView.lastChild);
        }
      }
    },

    //validation
    validateCompany() {
      let result;
      if (this.$v.companyVisitor != null) {
        const { $dirty, $error } = this.$v.companyVisitor;
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validateContactNumber() {
      let result;
      if (this.contact_number != null) {
        this.contact_number = this.contact_number.replace(/-/g, "");
      }

      if (this.$v.contact_number != null) {
        const { $dirty, $error } = this.$v.contact_number;
        result = $dirty ? !$error : null;
        this.result = result;
        return result;
      }
    },
    validateCarPlateNo() {
      let result;
      if (this.$v.carPlateNo != null) {
        if (this.istriggerValidation && this.$v.carPlateNo.$model == "-") {
          this.$v.carPlateNo.$model = null;
        }
        const { $dirty, $error } = this.$v.carPlateNo;
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validateFormUpdate(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.formUpdate[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },

    onSubmitValidationVisitorForm() {
      let $this = this;
      let result = null;
      let idNumber =
        this.selectedIDType == "nric_number"
          ? $this.$v.formUpdate.nric_number.$anyError
          : $this.$v.formUpdate.passport_number.$anyError;

      $this.$v.formUpdate.$touch();
      $this.$v.contact_number.$touch();
      $this.$v.companyVisitor.$touch();
      $this.$v.carPlateNo.$touch();

      this.isFingerprintRequired =
        this.getPreference.additional_metadata.vms_credential_operator_fingerprint;

      result =
        ($this.$v.formUpdate.$anyError && idNumber) ||
        $this.$v.contact_number.$anyError ||
        $this.$v.companyVisitor.$anyError ||
        (this.getPreference.additional_metadata
          .vms_credential_operator_license_plate_recognition &&
          $this.$v.carPlateNo.$anyError) ||
        (this.uploadImageRequired() && this.formUpdate.face_image == null) ||
        (!this.fingerprint1 && !this.fingerprint2 && this.isFingerprintRequired)
          ? false
          : true;

      return result;
    },

    //update visitor
    onSubmitUpdateForm: function (e) {
      this.istriggerValidation = true;
      e.preventDefault();
      let $this = this;
      let validForm = $this.onSubmitValidationVisitorForm();
      if (validForm) {
        let idNumber =
          this.selectedIDType == "passport_number"
            ? this.formUpdate.passport_number
            : this.formUpdate.nric_number;
        let country_code = $(".country-selector__input").val();
        $this.formUpdate.contact_number = country_code + $this.contact_number;
        $this.formUpdate.profile.gender = $this.selectedVisitorGender;
        $this.formUpdate.profile.company = $this.companyVisitor;
        $this.formUpdate.custom_id =
          this.getPreference.additional_metadata.vms_pdpa_setting ==
          "save_partial"
            ? this.$parent.$parent.$parent.getCustomId(this.formUpdate)
            : null;

        $this.formUpdate.passport_number =
          this.getPreference.additional_metadata.vms_pdpa_setting ==
          "save_partial"
            ? this.$parent.$parent.$parent.getPartial(
                this.formUpdate.passport_number
              )
            : this.formUpdate.passport_number;

        $this.formUpdate.nric_number =
          this.getPreference.additional_metadata.vms_pdpa_setting ==
          "save_partial"
            ? this.$parent.$parent.$parent.getPartial(
                this.formUpdate.nric_number
              )
            : this.formUpdate.nric_number;

        $this.formUpdate.profile.car_plate_no = this.getPreference
          .additional_metadata.vms_credential_operator_license_plate_recognition
          ? this.carPlateNo != null
            ? this.carPlateNo
            : "-"
          : $this.formUpdate.profile.car_plate_no != "-" ||
            $this.formUpdate.profile.car_plate_no != ""
          ? $this.formUpdate.profile.car_plate_no
          : "-";

        let template1 = null;
        let template2 = null;

        let text = "Please verify your latest information : ";
        let h = this.$createElement;
        let message = null;
        if (
          this.getPreference.additional_metadata
            .vms_credential_operator_fingerprint
        ) {
          this.formUpdate.profile.fingerprint.template1 =
            this.scanFingerprint1 != null
              ? this.scanFingerprint1
              : this.formUpdate.profile.fingerprint.template1;
          this.formUpdate.profile.fingerprint.template2 =
            this.scanFingerprint2 != null
              ? this.scanFingerprint2
              : this.formUpdate.profile.fingerprint.template2;

          /* template1 = this.formUpdate?.profile?.fingerprint?.template1
            ? "fingerprint template 1"
            : "-";
          template2 = this.formUpdate?.profile?.fingerprint?.template2
            ? "fingerprint template 2"
            : "-"; */

          if (
            this.formUpdate?.profile?.fingerprint?.template1 &&
            !this.formUpdate?.profile?.fingerprint?.template2
          ) {
            message = h("div", [
              h("h1", text),
              h("p"),
              h("p", [
                h("strong", "Full name : "),
                h("span", this.formUpdate.name)
              ]),
              h("p", [
                h("strong", "Company : "),
                h("span", this.formUpdate.profile.company)
              ]),
              h("p", [
                h("strong", "Gender : "),
                h("span", this.formUpdate.profile.gender)
              ]),
              h("p", [
                h("strong", "Email : "),
                h("span", this.formUpdate.email)
              ]),
              h("p", [
                h("strong", "Car Plate No. : "),
                h(
                  "span",
                  this.formUpdate.profile.car_plate_no != ""
                    ? this.formUpdate.profile.car_plate_no
                    : "-"
                )
              ]),
              h("p", [
                h("strong", "Contact Number : "),
                h("span", this.formUpdate.contact_number)
              ]),
              h("p", [
                h("strong", "NRID / Passport Number : "),
                h("span", idNumber)
              ]),
              h("p", [
                h("strong", "Fingerprint Template 1 : "),
                h("span", "fingerprint template 1")
              ])
            ]);
          } else if (
            !this.formUpdate?.profile?.fingerprint?.template1 &&
            this.formUpdate?.profile?.fingerprint?.template2
          ) {
            message = h("div", [
              h("h1", text),
              h("p"),
              h("p", [
                h("strong", "Full name : "),
                h("span", this.formUpdate.name)
              ]),
              h("p", [
                h("strong", "Company : "),
                h("span", this.formUpdate.profile.company)
              ]),
              h("p", [
                h("strong", "Gender : "),
                h("span", this.formUpdate.profile.gender)
              ]),
              h("p", [
                h("strong", "Email : "),
                h("span", this.formUpdate.email)
              ]),
              h("p", [
                h("strong", "Car Plate No. : "),
                h(
                  "span",
                  this.formUpdate.profile.car_plate_no != ""
                    ? this.formUpdate.profile.car_plate_no
                    : "-"
                )
              ]),
              h("p", [
                h("strong", "Contact Number : "),
                h("span", this.formUpdate.contact_number)
              ]),
              h("p", [
                h("strong", "NRID / Passport Number : "),
                h("span", idNumber)
              ]),
              h("p", [
                h("strong", "Fingerprint Template 2 : "),
                h("span", "fingerprint template 2")
              ])
            ]);
          } else {
            message = h("div", [
              h("h1", text),
              h("p"),
              h("p", [
                h("strong", "Full name : "),
                h("span", this.formUpdate.name)
              ]),
              h("p", [
                h("strong", "Company : "),
                h("span", this.formUpdate.profile.company)
              ]),
              h("p", [
                h("strong", "Gender : "),
                h("span", this.formUpdate.profile.gender)
              ]),
              h("p", [
                h("strong", "Email : "),
                h("span", this.formUpdate.email)
              ]),
              h("p", [
                h("strong", "Car Plate No. : "),
                h(
                  "span",
                  this.formUpdate.profile.car_plate_no != ""
                    ? this.formUpdate.profile.car_plate_no
                    : "-"
                )
              ]),
              h("p", [
                h("strong", "Contact Number : "),
                h("span", this.formUpdate.contact_number)
              ]),
              h("p", [
                h("strong", "NRID / Passport Number : "),
                h("span", idNumber)
              ]),
              h("p", [
                h("strong", "Fingerprint Template 1 : "),
                h("span", "fingerprint template 1")
              ]),
              h("p", [
                h("strong", "Fingerprint Template 2 : "),
                h("span", "fingerprint template 2")
              ])
            ]);
          }
        } else {
          message = h("div", [
            h("h1", text),
            h("p"),
            h("p", [
              h("strong", "Full name : "),
              h("span", this.formUpdate.name)
            ]),
            h("p", [
              h("strong", "Company : "),
              h("span", this.formUpdate.profile.company)
            ]),
            h("p", [
              h("strong", "Gender : "),
              h("span", this.formUpdate.profile.gender)
            ]),
            h("p", [h("strong", "Email : "), h("span", this.formUpdate.email)]),
            h("p", [
              h("strong", "Car Plate No. : "),
              h(
                "span",
                this.formUpdate.profile.car_plate_no != ""
                  ? this.formUpdate.profile.car_plate_no
                  : "-"
              )
            ]),
            h("p", [
              h("strong", "Contact Number : "),
              h("span", this.formUpdate.contact_number)
            ]),
            h("p", [
              h("strong", "NRID / Passport Number : "),
              h("span", idNumber)
            ])
          ]);
        }

        console.log("formUpdate", this.formUpdate);
        this.$bvModal
          .msgBoxConfirm([message], {
            centered: true
          })
          .then((value) => {
            if (value) {
              let API_URL =
                $this.getAPIServerURL +
                "/api/visitorservice/visitor/?visitor_id=" +
                $this.formUpdateID;
              const client = $this.$root.getAjaxFetchClient();
              client
                .putRequest(API_URL, $this.formUpdate)
                .then((data) => {
                  outer_loop: for (let key in data.result) {
                    let updateVisitor;
                    updateVisitor = data.result[key];
                    if (updateVisitor == null) {
                      // update visitor to store
                      $this.$store.dispatch("visitor/updateVisitor", {
                        id: $this.formUpdateID,
                        data: {
                          face_image: $this.formUpdate.face_image,
                          name: $this.formUpdate.name,
                          email: $this.formUpdate.email,
                          nric_number: $this.formUpdate.nric_number,
                          passport_number: $this.formUpdate.passport_number,
                          contact_number: $this.formUpdate.contact_number,
                          custom_id: $this.formUpdate.custom_id,
                          profile: {
                            gender: $this.selectedVisitorGender,
                            car_plate_no: $this.formUpdate.profile.car_plate_no,
                            company: $this.companyVisitor,
                            fingerprint: {
                              template1:
                                $this.formUpdate.profile.fingerprint.template1,
                              template2:
                                $this.formUpdate.profile.fingerprint.template2
                            }
                          }
                        }
                      });

                      $this.formUpdateID = null;
                      $this.formUpdate.face_image = null;
                      $this.formUpdate.name = null;
                      $this.formUpdate.email = null;
                      $this.formUpdate.nric_number = null;
                      $this.formUpdate.passport_number = null;
                      $this.formUpdate.contact_number = null;
                      $this.formUpdate.custom_id = null;
                      $this.formUpdate.profile.gender = null;
                      $this.formUpdate.profile.car_plate_no = null;
                      $this.formUpdate.profile.company = null;
                      $this.formUpdate.profile.fingerprint.template1 = null;
                      $this.formUpdate.profile.fingerprint.template2 = null;

                      $this.selectedIDName = null;
                      $this.contact_number = null;
                      $this.companyVisitor = null;

                      $this.formUpdateErrorMessage = null;

                      $this.updateShowTakePhoto = false;
                      $this.updateShowUploadPhoto = false;

                      $this.countTakePhoto = 0;

                      this.$v.$reset();
                      $this.stopCamera();
                      this.istriggerValidation = false;

                      $this.referenceParent.formUpdateVisitor.hide();

                      $this.$store.dispatch("session/addGlobalAlertMessage", {
                        message_text: "Updated visitor successfully",
                        message_type: "success"
                      });
                    } else {
                      if (data.detail != undefined) {
                        $this.formUpdateErrorMessage = data.detail;
                        this.istriggerValidation = false;
                      }
                    }
                    break outer_loop;
                  }
                })
                .catch((err) => {
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: err.detail,
                    message_type: "danger"
                  });
                  // if (err.status == 401) {
                  //   $this.$store.dispatch("session/logoutSession");
                  // }
                });
            }
          })
          .catch((err) => {
            // An error occurred
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
          });
      } else {
        if (this.uploadImageRequired() && this.formUpdate.face_image == null) {
          $this.$bvModal.msgBoxOk(
            "Please upload or take a picture for profile picture.",
            {
              centered: true
            }
          );
          return false;
        }

        /* $this.$bvModal.msgBoxOk("Form is not completed!", {
          centered: true
        });
        return false; */
      }
    },

    // setting image required
    uploadImageRequired() {
      let result = null;
      result =
        /* this.formUpdate.face_image == null || */
        this.getPreference?.additional_metadata
          ?.vms_registration_visitor_profile_picture_required ||
        this.getPreference?.additional_metadata
          ?.vms_credential_operator_face_recognition
          ? true
          : false;

      return result;
    },

    //readIcCardInfo
    readIcCardInfo() {
      this.scanUpdateErrorMessage = null;
      this.isDisabled = true;

      let $this = this;
      let url = "http://127.0.0.1:28080/api/card/info/";
      let req = new Request(url);
      fetch(req, {
        method: "GET"
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("result", data);
          $this.isDisabled = false;
          $this.selectedIDName = data.DocumentNumber.replace(/-/g, "");
          console.log("$this.selectedIDName", $this.selectedIDName);
          if (
            $this.selectedIDType === "nric_number" &&
            (data.DocumentType === "Identity Card" ||
              data.DocumentType === "MyKad")
          ) {
            $this.formUpdate.nric_number = $this.selectedIDName;
            $this.formUpdate.name = data.Name;
            if (data.Gender == "PEREMPUAN" || data.Gender == "Female") {
              $this.selectedVisitorGender = "female";
              $this.formUpdate.profile.gender = $this.selectedVisitorGender;
            } else {
              $this.selectedVisitorGender = "male";
              $this.formUpdate.profile.gender = $this.selectedVisitorGender;
            }
            $this.formUpdate.face_image = data.Photo;

            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Successfully scan the ic",
              message_type: "success"
            });
          } else if (
            $this.selectedIDType === "passport_number" &&
            data.DocumentType !== "Identity Card" &&
            data.DocumentType !== "MyKad"
          ) {
            $this.formUpdate.passport_number = $this.selectedIDName;
            $this.formUpdate.name = data.Name;
            if (data.Gender == "PEREMPUAN" || data.Gender == "Female") {
              $this.selectedVisitorGender = "female";
              $this.formUpdate.profile.gender = $this.selectedVisitorGender;
            } else {
              $this.selectedVisitorGender = "male";
              $this.formUpdate.profile.gender = $this.selectedVisitorGender;
            }
            $this.formUpdate.face_image = data.Photo;

            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Successfully scan the passport",
              message_type: "success"
            });
          } else {
            let message_text = "Please choose ID type correctly.";
            $this.scanUpdateErrorMessage = message_text;

            $this.selectedIDName = null;
            $this.selectedVisitorGender = null;

            $this.formUpdate.name = null;
            $this.formUpdate.face_image = null;
            $this.formUpdate.profile.gender = $this.selectedVisitorGender;
          }

          this.updateShowUploadPhoto = true;
        })
        .catch((err) => {
          this.isDisabled = false;
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err,
            message_type: "danger"
          });
        });
    },
    // readFingerprint
    readFingerprint() {
      /*  let data = {
        Template1:
          "45201114974A534217C0A0AB8A1EC1404E0626C161A7070C01F1B08A0E44110507180570AF881F46104B8432C6E19F043EC7319E8D23C7D0A4891D09B04F06144A50030A1E0A51AA07278AA1A5883DCB909F0235CBA142871B8C61AF890F0CA1620F184D81030C1B0D91508C244E21A485374F01998421CF50A38833CFF13F8536513094831711611F1A11D1F127962012209A83195270392A2952E09E880C92F02B0D281330468AFFFF12223333FFFFFFFF1112222333FFFFFF00112222334FFFFFE000122223344FFFFEE001222334444FFDDEE012223344444FDDEE011223334444FDDEE011223333344FDDEE0112233343444DDEEE112233344444DDDEE112233444444CCDDE012233444444CCCDD012233444444BCCCD012333444444BBBCC012334444445AAABBD13444445555AAABBC13455555555A9AAAA4445555555599999754444455556F888875443344555600000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
        Template2:
          "451F0E14924A53421082E0000F19C3D149032883F19A841E0421A28633055099833345B04084100620B10F1C06B0A4872B86C09A861D87E044851288C1AE921E4A513B072DCAF13B07110B7100B1030B80220F164C0098092F4C919584098D61280D294D70380B0D4DC02E8B0C0E2082880B4E703188230E81310733CF808E0E1BCFC18F0B06D0E1370932D1308B8C1E91718E8E0B518190071851C19308299281938CFFFFF23344FFFFFFFF1234444FFFFFFF13344444FFFFF0134444455FFDE0134444445FDEE0133344445FDDE0133344445FDDE0133344444FCDE01334444445CCDE1344444445BCCE0445555555ABBCE55555555F9AAA966666555F9999766666555F8887766776666F7766667777777F7666666677777F766555566667FF766554455544FFF6555444433FFF000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"
      };

      this.fingerprint1 = data?.Template1 ? true : false;
      this.fingerprint2 = data?.Template2 ? true : false;
      this.scanFingerprint1 = data?.Template1 ?? null;
      this.scanFingerprint2 = data?.Template2 ?? null;
      this.formUpdate.profile.fingerprint.template1 = data?.Template1 ?? null;
      this.formUpdate.profile.fingerprint.template2 = data?.Template2 ?? null;

      this.isFingerprintRequired = false; */

      let $this = this;
      let url = "http://127.0.0.1:28080/api/fingerprint/info/";
      let req = new Request(url);
      fetch(req, {
        method: "GET"
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("result", data);
          this.fingerprint1 = data?.Template1 ? true : false;
          this.fingerprint2 = data?.Template2 ? true : false;
          this.scanFingerprint1 = data?.Template1 ?? null;
          this.scanFingerprint2 = data?.Template2 ?? null;
          this.formUpdate.profile.fingerprint.template1 =
            data?.Template1 ?? null;
          this.formUpdate.profile.fingerprint.template2 =
            data?.Template2 ?? null;

          this.isFingerprintRequired = false;
        })
        .catch((err) => {
          this.isDisabled = false;
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err,
            message_type: "danger"
          });
        });
    },
    //delete fingerprint
    deleteFingerprintHandler(template) {
      this.$bvModal
        .msgBoxConfirm("Are you sure to remove fingerprint " + template + "?", {
          okTitle: "Yes",
          cancelTitle: "No",
          centered: true
        })
        //return false;
        .then(async (value) => {
          if (value) {
            template == "template1"
              ? ((this.fingerprint1 = false),
                (this.scanFingerprint1 = null),
                (this.formUpdate.profile.fingerprint.template1 = null))
              : ((this.fingerprint2 = false),
                (this.scanFingerprint2 = null),
                (this.formUpdate.profile.fingerprint.template2 = null));
          }
        })
        .catch((err) => {
          // An error occurred
          return false;
        });
    }
  }
};
</script>

<style></style>
