<template>
  <div>
    <h1 class="mt-3">Visit Registration Form</h1>
    <b-form>
      <b-card class="bg-surface-2">
        <b-form-group label="Company to visit">
          <span :class="{ invalid: $v.selectedHostCompanyObj.$error }">
            <multiselect
              id="multiselect_company"
              v-model="$v.selectedHostCompanyObj.$model"
              :options="companies"
              placeholder="Please start typing...."
              label="name"
              track-by="id"
              :searchable="true"
              :loading="companyIsLoading"
              @search-change="asyncFindCompany"
              @select="handleSelectCompany"
              :state="validateMultiselect('selectedHostCompanyObj')"
              aria-describedby="selectedHostCompanyObj_feedback"
              :disabled="allow"
            >
              <span slot="noResult">{{
                companyQuery != null && companyQuery.length >= 3
                  ? "No result"
                  : "Enter at least 3 characters to search"
              }}</span>
            </multiselect>
          </span>
          <div
            v-if="isValidSelectedHostCompanyObj"
            class="invalid-multiselect-feedback text-danger"
            id="selectedHostCompanyObj_feedback"
          >
            This field is required.
          </div>
        </b-form-group>

        <b-form-group label="Host Name" v-if="selectedHostCompanyObj != null">
          <span :class="{ invalid: $v.selectedHostNameObj.$error }">
            <multiselect
              id="multiselect_host"
              v-model="$v.selectedHostNameObj.$model"
              :options="hosts"
              placeholder="Please start typing...."
              label="name"
              track-by="id"
              :searchable="true"
              :loading="hostIsLoading"
              @search-change="asyncFindHost"
              :state="validateMultiselect('selectedHostNameObj')"
              aria-describedby="selectedHostNameObj_feedback"
              :disabled="allow"
            >
              <span slot="noResult">{{
                hostQuery != null && hostQuery.length >= 3
                  ? "No result"
                  : "Enter at least 3 characters to search"
              }}</span>
            </multiselect>
          </span>
          <div
            v-if="isValidSelectedHostNameObj"
            class="invalid-multiselect-feedback text-danger"
            id="selectedHostNameObj_feedback"
          >
            This field is required.
          </div>
        </b-form-group>

        <b-form-group label="Visitor Type">
          <b-form-select
            v-model="$v.visitInfo.visitor_type.$model"
            :disabled="allow"
            :options="visitorTypeOptions"
            :state="validateVisitInfo('visitor_type')"
            class="mb-1"
            text-field="name"
            value-field="name"
            aria-describedby="visitInfo.visitor_type_feedback"
            @change="handleChange"
          ></b-form-select>
          <b-form-invalid-feedback
            class="invalid-feedback"
            id="visitInfo.visitor_type_feedback"
          >
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- id="multiselect_visitor" -->
        <b-form-group label="Visitor Name" v-if="!isPublic">
          <span :class="{ invalid: $v.selectedVisitorObj.$error }">
            <multiselect
              ref="multiselect_visitor"
              label="name"
              track-by="id"
              placeholder="Please start typing...."
              aria-describedby="selectedVisitorObj_feedback"
              v-model="$v.selectedVisitorObj.$model"
              @search-change="asyncFindVisitor"
              :options="visitors"
              :searchable="true"
              :loading="visitorIsLoading"
              :state="validateMultiselect('selectedVisitorObj')"
              :disabled="allow"
              :max-height="1000"
            >
              <template slot="singleLabel" slot-scope="props">
                <div class="option__desc row">
                  <span class="option__title col">{{ props.option.name }}</span>
                  <span class="option__small col">{{
                    props.option.nric_number != undefined
                      ? props.option.nric_number
                      : props.option.passport_number | dashForEmptyText
                  }}</span>
                  <span class="option__small col">{{
                    props.option.contact_number | dashForEmptyText
                  }}</span>
                </div>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc row">
                  <span class="option__title col">{{ props.option.name }}</span>
                  <span class="option__small col">{{
                    props.option.nric_number != undefined
                      ? props.option.nric_number
                      : props.option.passport_number | dashForEmptyText
                  }}</span>
                  <span class="option__small col">{{
                    props.option.contact_number | dashForEmptyText
                  }}</span>
                </div>
              </template>

              <span
                v-if="visitorQuery !== null && visitorQuery.length >= 3"
                slot="beforeList"
                @click="showAddVisitorModal"
              >
                <div class="my-3 mx-3">
                  <i class="fas fa-user-plus mr-2"></i> Create new visitor
                </div>
              </span>

              <span slot="noResult">{{
                visitorQuery !== null && visitorQuery.length >= 3
                  ? "No result"
                  : "Enter at least 3 characters to search"
              }}</span>
            </multiselect>
          </span>
          <div
            v-if="isValidSelectedVisitorObj"
            class="invalid-multiselect-feedback text-danger"
            id="selectedVisitorObj_feedback"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-card>

      <!-- advanced options -->
      <b-card
        v-if="parents_url === 'create-visit'"
        class="mt-3 bg-surface-2"
        no-body
      >
        <b-card-header header-tag="header" role="tab">
          <div v-b-toggle.advance class="d-flex justify-content-between">
            <div class="h6">
              <strong> Advanced Options</strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="advance" role="tabpanel">
          <b-card-body>
            <b-form-group
              id="select-group-registration-type"
              label="Registration Type"
              label-for="select-registration-type"
            >
              <b-form-select
                id="select-registration-type"
                v-model="$v.visitInfo.registration_type.$model"
                :options="registrationTypeOptions"
                :state="validateVisitInfo('registration_type')"
                aria-describedby="visitList.registration_type_feedback"
                :disabled="allow"
              ></b-form-select>
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="visitList.registration_type_feedback"
              >
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-visit-start-date"
              label="Visit Start Date"
              label-for="input-visit-start-date"
            >
              <b-form-datepicker
                id="input-visit-start-date"
                v-model="$v.visitInfo.visit_start_date.$model"
                :state="validateVisitInfo('visit_start_date')"
                aria-describedby="visitList.visit_start_date_feedback"
                :disabled="allow"
              ></b-form-datepicker>
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="visitList.visit_start_date_feedback"
              >
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-visit-start-time"
              label="Visit Start Time"
              label-for="input-visit-start-time"
            >
              <b-form-timepicker
                id="input-visit-start-time"
                v-model="$v.visitInfo.visit_start_time.$model"
                :hourCycle="this.hourCycle"
                :hour12="this.hour12"
                locale="en"
                show-seconds
                :state="validateVisitInfo('visit_start_time')"
                aria-describedby="visitList.visit_start_time_feedback"
                :disabled="allow"
              ></b-form-timepicker>
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="visitList.visit_start_time_feedback"
              >
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-visit-end-date"
              label="Visit End Date"
              label-for="input-visit-end-date2"
            >
              <b-form-datepicker
                id="input-visit-end-date2"
                v-model="$v.visitInfo.visit_end_date.$model"
                :state="validateVisitInfo('visit_end_date')"
                aria-describedby="visitList.visit_end_date_feedback"
                :disabled="allow"
              ></b-form-datepicker>
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="visitList.visit_end_date_feedback"
              >
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-visit-end-time"
              label="Visit End Time"
              label-for="input-visit-end-time"
            >
              <b-form-timepicker
                id="input-visit-end-time"
                v-model="$v.visitInfo.visit_end_time.$model"
                :hourCycle="this.hourCycle"
                :hour12="this.hour12"
                locale="en"
                show-seconds
                :state="validateVisitInfo('visit_end_time')"
                aria-describedby="visitList.visit_end_time_feedback"
                :disabled="allow"
              ></b-form-timepicker>
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="visitList.visit_end_time_feedback"
              >
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-tolerance-time-in"
              label="Tolerance Time In (Min)"
              label-for="input-tolerance-time-in"
            >
              <b-form-input
                id="input-tolerance-time-in"
                v-model="$v.visitInfo.tolerance_time_in.$model"
                :state="validateVisitInfo('tolerance_time_in')"
                aria-describedby="visitList.tolerance_time_in_feedback"
                type="number"
                :disabled="allow"
              ></b-form-input>
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="visitList.tolerance_time_in_feedback"
              >
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-tolerance-time-out"
              label="Tolerance Time Out(Min)"
              label-for="input-tolerance-time-out"
            >
              <b-form-input
                id="input-tolerance-time-out"
                v-model="$v.visitInfo.tolerance_time_out.$model"
                :state="validateVisitInfo('tolerance_time_out')"
                aria-describedby="visitList.tolerance_time_out_feedback"
                type="number"
                :disabled="allow"
              ></b-form-input>
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="visitList.tolerance_time_out_feedback"
              >
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-visit-reason"
              label="Visit Reason"
              label-for="input-visit-reason"
            >
              <b-form-input
                id="input-visit-reason"
                v-model="visitInfo.visit_purpose"
                :disabled="allow"
              ></b-form-input>
            </b-form-group>
          </b-card-body>
        </b-collapse>
      </b-card>

      <!-- declaration form -->
      <b-card
        v-if="
          parents_url === 'kiosk2' &&
          getPreference.additional_metadata.vms_registration_declaration_form
        "
        class="mt-3 bg-surface-2"
        no-body
      >
        <b-card-header header-tag="header" role="tab">
          <div
            v-b-toggle.declaration_form_section
            class="d-flex justify-content-between"
          >
            <div class="h6">
              <strong> Declaration Form </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="declaration_form_section" role="tabpanel">
          <b-card-body>
            <!-- <pre>
            {{this.declarationFormMetadata()}}
            </pre> -->
            <b-form id="declaration_form" class="form-preview"></b-form>
          </b-card-body>
        </b-collapse>
      </b-card>

      <!-- new visitor details -->
      <b-card v-if="isPublic" class="mt-3 bg-surface-2" no-body>
        <b-card-header header-tag="header" role="tab">
          <div v-b-toggle.advance class="d-flex justify-content-between">
            <div class="h6">
              <strong>New Visitor Details</strong>
            </div>
            <!-- <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span> -->
          </div>
        </b-card-header>
        <b-card-body>
          <addVisitor
            ref="referencesChild"
            :referenceParent="this.$refs"
            :isPublic="true"
            :public_preference="this.public_preference"
            :getCountryCode="this.getCountryCode"
          ></addVisitor>
        </b-card-body>
      </b-card>

      <!-- submit details -->
      <div class="mt-3 d-flex justify-content-start">
        <b-button
          variant="primary"
          @click="submitAndCreateVisit"
          :disabled="allow"
          >Submit
        </b-button>
        <b-button class="ml-2" @click="handleResetForm" :disabled="allow"
          >Clear
        </b-button>
      </div>
      <!--        </b-card>-->
    </b-form>
    <!-- add visitor modal -->
    <b-modal
      id="form-add-visitor"
      title="Add Visitor"
      ref="formAddVisitor"
      centered
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      :hide-footer="true"
      @close="stopCameraModal()"
    >
      <addVisitor
        ref="referencesChild"
        :isRegister="true"
        :referenceParent="$refs"
        :parentUrl="parents_url"
        :getPreference="getPreference"
        :getCountryCode="getCountryCode"
      ></addVisitor>
    </b-modal>

    <!-- update visitor modal -->
    <b-modal
      id="form-update-visitor"
      title="Update Visitor"
      ref="formUpdateVisitor"
      centered
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      :hide-footer="true"
      @close="stopCameraModal()"
    >
      <updateVisitor
        ref="referencesChild"
        :isRegister="true"
        :referenceParent="$refs"
        :parentUrl="parents_url"
        :getPreference="getPreference"
        :getCountryCode="getCountryCode"
        :updateData="selectedVisitorObj"
        :idUpdate="selectedVisitorObj?.id"
        :idType="selectedIDType"
        :idName="selectedIDName"
        :showPhoto="updateShowUploadPhoto"
      ></updateVisitor>
    </b-modal>

    <!-- image modal -->
    <b-modal
      id="form-add-visitor-image-2"
      title="Profile Picture"
      centered
      hide-footer
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      @close="stopCameraModal()"
    >
      <addVisitor ref="referencesChild" :isAddImage="true"></addVisitor>
    </b-modal>

    <!-- error message operator modal -->
    <b-modal
      id="form-error-message-car-plate-no-operator"
      title="Error Message"
      centered
      ok-only
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="selectedVisitorObj != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>
            &nbsp; {{ selectedVisitorObj.name | humanizeText }} 's car plate
            number is empty.
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>
            You can click on this
            <router-link
              :to="{
                name: 'visitors',
                query: {
                  keyword: selectedVisitorObj?.name
                }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a :href="href" @click="navigate"
                ><span style="color: blue"><u>link</u></span></a
              >
            </router-link>
            or go to Manage Visitors to update it.
          </td>
        </tr>
      </table>
    </b-modal>

    <b-modal
      id="form-error-message-fingerprint-operator"
      title="Error Message"
      centered
      ok-only
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="selectedVisitorObj != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>
            &nbsp; {{ selectedVisitorObj.name | humanizeText }} 's fingerprint
            is empty.
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>
            You can click on this
            <router-link
              :to="{
                name: 'visitors',
                query: {
                  keyword: selectedVisitorObj?.name
                }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a :href="href" @click="navigate"
                ><span style="color: blue"><u>link</u></span></a
              >
            </router-link>
            or go to Manage Visitors to update it.
          </td>
        </tr>
      </table>
    </b-modal>

    <!-- error message-both-operator modal -->
    <b-modal
      id="form-error-message-both-operator"
      title="Error Message"
      centered
      ok-only
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="selectedVisitorObj != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>
            &nbsp; {{ selectedVisitorObj.name | humanizeText }} 's car plate
            number & fingerprint is empty.
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>
            You can click on this
            <router-link
              :to="{
                name: 'visitors',
                query: {
                  keyword: selectedVisitorObj?.name
                }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a :href="href" @click="navigate"
                ><span style="color: blue"><u>link</u></span></a
              >
            </router-link>
            or go to Manage Visitors to update it.
          </td>
        </tr>
      </table>
    </b-modal>

    <!-- error message-kiosk modal -->
    <b-modal
      id="form-error-message-car-plate-no-kiosk"
      title="Error Message"
      centered
      ok-only
      @ok="showAddVisitorModal"
      @close="showAddVisitorModal"
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="selectedVisitorObj != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>&nbsp; Our system has been updated.</td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>Please register as a new visitor.</td>
        </tr>
      </table>
    </b-modal>

    <b-modal
      id="form-error-message-fingerprint-kiosk"
      title="Error Message"
      centered
      ok-only
      @ok="showAddVisitorModal"
      @close="showAddVisitorModal"
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="selectedVisitorObj != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>&nbsp; Our system has been updated.</td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>Please register as a new visitor.</td>
        </tr>
      </table>
    </b-modal>

    <!-- error message-both-kiosk modal -->
    <b-modal
      id="form-error-message-both-kiosk"
      title="Error Message"
      centered
      ok-only
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      v-if="selectedVisitorObj != null"
    >
      <table>
        <tr>
          <td>
            <h1><i class="fa fa-exclamation-circle" style="color: red"></i></h1>
          </td>
          <td>&nbsp; Our system has been updated.</td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>Please register as a new visitor.</td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  number,
  helpers
} from "vuelidate/lib/validators"; //helpers } from "vuelidate/lib/validators";
import moment from "moment";
import Multiselect from "vue-multiselect";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import * as imageConversion from "image-conversion";
let convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

import jQuery from "jquery";
let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

import addVisitor from "@/components/SmartVMS/Visitor/AddVisitor.vue";
import updateVisitor from "@/components/SmartVMS/Visitor/UpdateVisitor.vue";

let current = moment();
// const regex_nric = helpers.regex(regex_nric, /^\d{6}-\d{2}-\d{4}$/);
//helpers = /^\d{6}-\d{2}-\d{4}$/;
export default {
  components: {
    Multiselect,
    addVisitor,
    updateVisitor
  },
  mixins: [validationMixin],
  props: {
    isPublic: {
      type: Boolean,
      required: false
    },
    parents_url: {
      type: String,
      required: false,
      default: "create-visit"
    }
  },
  data: function () {
    return {
      public_nationalIDOptions: [],
      hourCycle: "h23",
      hour12: false,

      companyIsLoading: false,
      companyQuery: null,
      companies: [],

      hostIsLoading: false,
      hostQuery: null,
      hosts: [],

      visitorIsLoading: false,
      visitorQuery: null,
      visitors: [],

      isValidSelectedHostCompanyObj: false,
      isValidSelectedHostNameObj: false,
      isValidSelectedVisitorObj: false,

      /** user selected data **/
      selectedHostNameObj: null,
      selectedHostCompanyObj: null,
      selectedVisitorObj: null,

      companysList: {},

      visitInfo: {
        registration_type: "WALK_IN",
        visitor_type: "",
        visit_start_date: moment().format("YYYY-MM-DD"),
        visit_end_date: moment().format("YYYY-MM-DD"),
        visit_start_time: moment().format("HH:mm:ss"),
        visit_end_time: moment().add(2, "hours").format("HH:mm:ss"),
        tolerance_time_in: 0,
        tolerance_time_out: 0,
        visit_purpose: null,
        additional_metadata: {}
      },

      public_preference: {
        visit_end_time: "",
        visit_start_time: "",
        tolerance_time_in: 0,
        tolerance_time_out: 0,
        forms: {},
        forms_compulsory_fields: {},
        additional_metadata: {
          national_id_type: {},
          vms_public_branding_image: null,
          vms_public_welcome_message: "-",
          vms_preferences_default_visitor_type: "",
          vms_registration_visitor_profile_picture_required: false,
          vms_public_duration_hours: 2,
          vms_public_visit_end_time: "18:00:00",
          vms_public_default_duration: "duration_time_range",
          vms_public_visit_start_time: "10:00:00",
          vms_public_visit_end_time_only: "18:00:00",
          vms_public_duration_days: 0
        },
        tnc_metadata: {
          tnc_url: "https://www.google.com/",
          policy_url: "https://www.google.com/"
        }
      },

      form: {
        access_types: [],
        access_groups: [],
        access_level: null,
        lift_access_level: null,
        access_card_no: null,
        selected_acs_url: null,
        qr_code_type: null
      },

      acsUrls: [],
      all_access_groups: {},
      all_access_levels: {},

      registrationTypeOptions: [
        { value: "WALK_IN", text: "WALK IN" },
        { value: "INVITE", text: "INVITE" }
      ],
      visitorTypeOptions: [],

      allow: false,
      formRender: [],
      countTakePhoto: 0,
      defaultImage: require("@/assets/global/images/icons/profile_sample.jpg"),

      visit_data: null,
      checkIn_data: null,
      selectedIDType: null,
      selectedIDName: null,
      updateShowUploadPhoto: false
    };
  },
  watch: {
    getPreference: {
      deep: true,
      handler: function (newVal) {
        console.log("getPreference", newVal);
        this.visitInfo.tolerance_time_in = newVal?.tolerance_time_in;
        this.visitInfo.tolerance_time_out = newVal?.tolerance_time_out;
        this.visitInfo.visitor_type =
          newVal?.additional_metadata?.vms_preferences_default_visitor_type;
        this.parents_url == "create-visit"
          ? this.getTimeOperator(newVal?.additional_metadata)
          : this.getTime(newVal?.additional_metadata);
        newVal?.additional_metadata?.vms_registration_declaration_form
          ? this.setFormData(newVal?.forms?.formData)
          : null;
      }
    },
    getSettings: {
      deep: true,
      handler: function (newVal) {
        console.log("getSettings", newVal);
      }
    }
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getCountryCode: "session/getCountryCode"
    }),
    getPreference() {
      return this.$store.getters["vmsPreferences2Module/getPreferences"];
    },
    getSettings() {
      return this.$store.getters["vmsSettingsModule/getSettings"];
    }
  },
  mounted: function () {
    this.getVisitorType();
    if (this.isPublic) {
      this.initPublic();
    } else {
      this.init();
      this.getSetting();
    }
  },
  validations: {
    selectedHostNameObj: { required },
    selectedHostCompanyObj: { required },
    selectedVisitorObj: { required },

    visitInfo: {
      registration_type: { required },
      visit_start_date: { required },
      visit_end_date: { required },
      visit_start_time: { required },
      visit_end_time: { required },
      visitor_type: { required },
      tolerance_time_in: { required },
      tolerance_time_out: { required }
    }
  },

  methods: {
    //initial
    initPublic() {
      let API_URL = this.getAPIServerURL + "/public/vms/visit-preference/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          this.visitInfo.tolerance_time_in = data?.tolerance_time_in;
          this.visitInfo.tolerance_time_out = data?.tolerance_time_out;
          this.visitInfo.visitor_type =
            data?.additional_metadata?.vms_preferences_default_visitor_type;
          this.visitInfo.visit_type =
            data?.additional_metadata?.vms_preferences_default_visitor_type;
          this.getTime(data?.additional_metadata);
          data?.additional_metadata?.vms_registration_declaration_form
            ? this.setFormData(data?.forms?.formData)
            : null;
          this.public_preference = data;
          console.log("this.public_preference ", this.public_preference);
          console.log("this.visitInfo ", this.visitInfo);
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    async init() {
      try {
        let p1Urls = await this.fetchP1Members();
        this.acsUrls = p1Urls;
        for (let i = 0; i < this.acsUrls.length; i++) {
          this.$set(
            this.all_access_groups,
            this.acsUrls[i],
            await this.fetchAccessGroup(this.acsUrls[i])
          );
          this.$set(
            this.all_access_levels,
            this.acsUrls[i],
            await this.fetchAccessLevel(this.acsUrls[i])
          );
        }
        this.form.selected_acs_url = this.acsUrls[0];
      } catch (e) {
        console.log(e);
      }
    },
    async fetchP1Members() {
      let API_URL =
        this.getAPIServerURL + "/api/monitorservice/members/?group=EP_P1";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl] != undefined) {
        return result[validUrl].result;
      }
      return [];
    },
    async fetchAccessGroup(acs_url) {
      var API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/access-groups/?acs_url=" +
        acs_url;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let error = res.detail;
      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (
            result[key] !== undefined &&
            Array.isArray(result[key][acs_url])
          ) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (error != undefined) {
        //error = "'ConnectionRefusedError' object is not subscriptable"
        //alert(error);
        let $this = this;
        this.$bvModal
          .msgBoxOk(
            "P1 is not connected. Please contact your system administrator. " +
              "If P1 is already connected, you should refresh the browser to be able to fill in the form again.",
            {
              centered: true,
              noCloseOnBackdrop: true,
              noCloseOnEsc: false
            }
          )
          .then((value) => {
            if (value) {
              this.allow = true;
            }
          })
          .catch((err) => {
            // An error occurred
          });
        return;
      }

      if (
        result[validUrl] != undefined &&
        Array.isArray(result[validUrl][acs_url])
      ) {
        return result[validUrl][acs_url];
      }
      return [];
    },
    async fetchAccessLevel(acs_url) {
      var API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/access-levels/?acs_url=" +
        acs_url;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (
            result[key] !== undefined &&
            Array.isArray(result[key][acs_url])
          ) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (
        result[validUrl] != undefined &&
        Array.isArray(result[validUrl][acs_url])
      ) {
        return result[validUrl][acs_url];
      }
      return [];
    },

    //search
    asyncFindCompany(query) {
      if (query.length >= 3) {
        let $this = this;
        let API_URL = $this.getAPIServerURL + "/public/vms/company/search/";
        const client = $this.$root.getAjaxFetchClient();

        query = query.charAt(0).toUpperCase() + query.slice(1);

        this.companyQuery = query;

        let data = {
          name: query
        };

        this.companyIsLoading = true;

        client
          .postRequest(API_URL, data, false, false)
          .then((data) => {
            console.log("companys data", data);
            let result = data.result;
            let validUrl = "";
            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined && Array.isArray(result[key])) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }
            if (
              result[validUrl] != undefined &&
              Array.isArray(result[validUrl])
            ) {
              this.companies = [];
              this.companies = result[validUrl];

              this.hosts = [];
              this.companyIsLoading = false;
            }
          })
          .catch((err) => {
            console.log("err", err);

            this.hosts = [];
            this.companies = [];
            this.selectedHostCompanyObj = null;
            this.companyIsLoading = false;
          });
      } else {
        return;
      }
    },
    asyncFindHost(query) {
      if (query.length >= 3) {
        let $this = this;
        let API_URL = $this.getAPIServerURL + "/public/vms/host/search/";
        const client = $this.$root.getAjaxFetchClient();

        query = query.charAt(0).toUpperCase() + query.slice(1);

        this.hostQuery = query;

        let data = {
          name: query,
          company_id: this.selectedHostCompanyObj.id
        };

        this.isValidSelectedHostNameObj = false;
        this.hostIsLoading = true;

        client
          .postRequest(API_URL, data, false, false)
          .then((data) => {
            let result = data.result;
            let validUrl = "";
            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined && Array.isArray(result[key])) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }
            if (
              result[validUrl] != undefined &&
              Array.isArray(result[validUrl])
            ) {
              this.hosts = [];
              for (let key5 in result[validUrl]) {
                if (result[validUrl][key5].is_active) {
                  $this.hosts.push(result[validUrl][key5]);
                }
              }

              this.isValidSelectedHostNameObj = false;
              this.hostIsLoading = false;
            }
          })
          .catch((err) => {
            console.log("err", err);
            this.hosts = [];
            this.hostIsLoading = false;
            this.selectedHostNameObj = null;
            this.isValidSelectedHostNameObj = false;
          });
      } else {
        return;
      }
    },
    asyncFindVisitor(query) {
      if (query.length >= 3) {
        let $this = this;
        let API_URL =
          $this.getAPIServerURL + "/api/visitorservice/visitor/search/";
        const client = $this.$root.getAjaxFetchClient();

        query = query.charAt(0).toUpperCase() + query.slice(1);

        this.visitorQuery = query;

        let data = {
          keyword: query
        };

        this.visitorIsLoading = true;
        this.isValidSelectedVisitorObj = false;

        client
          .getRequest(API_URL, data, false, false)
          .then((data) => {
            let result = data.result;
            let validUrl = "";
            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined && Array.isArray(result[key])) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }
            if (
              result[validUrl] != undefined &&
              Array.isArray(result[validUrl])
            ) {
              this.visitors = [];
              for (let key2 in result[validUrl]) {
                if (!result[validUrl][key2].is_blocklisted) {
                  if (
                    this.getPreference.additional_metadata.vms_pdpa_setting ==
                    "save_partial"
                  ) {
                    if (result[validUrl][key2].passport_number != null) {
                      result[validUrl][key2].passport_number = this.getPartial(
                        result[validUrl][key2].passport_number
                      );
                    } else {
                      result[validUrl][key2].nric_number = this.getPartial(
                        result[validUrl][key2].nric_number
                      );
                    }

                    result[validUrl][key2].custom_id = this.getCustomId(
                      result[validUrl][key2]
                    );
                  }
                  this.visitors.push(result[validUrl][key2]);
                }
              }

              this.isValidSelectedVisitorObj = false;
              this.visitorIsLoading = false;
            }
          })
          .catch((err) => {
            console.log("err", err);
            this.visitors = [];
            this.visitorIsLoading = false;
            this.selectedVisitorObj = null;
            this.isValidSelectedVisitorObj = false;
          });
      } else {
        return;
      }
    },

    //get
    getVisitorType() {
      let API_URL = null;
      if (this.isPublic) {
        API_URL = this.getAPIServerURL + "/public/vms/visitor-type/all/";
      } else {
        API_URL =
          this.getAPIServerURL + "/api/visitorservice/visitor-type/all/";
      }

      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          this.visitorTypeOptions = [];
          let result = data.result;
          let validUrl = "";
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined && Array.isArray(result[key])) {
                validUrl = key;
                break outer_loop;
              }
            }
          }
          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl])
          ) {
            for (let key2 in result[validUrl]) {
              result[validUrl][key2].name =
                result[validUrl][key2].name.toUpperCase();

              this.visitorTypeOptions.push(result[validUrl][key2]);
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    getPartial(input) {
      let length = input.length;
      let input2 = input.substring(0, length - 4).split("");
      let input3 = input.substring(length - 4);

      let output = [];
      input2.forEach((letter) => {
        letter = "*";
        output.push(letter);
      });

      let answer = output.join("") + input3;
      return answer;
    },
    getCustomId(data) {
      let contact_number = data.contact_number;
      let length1 = contact_number.length;
      contact_number = contact_number.substring(length1 - 4);

      //let email = data.email.substring(4, 0);

      let custom_id = null;

      if (data.passport_number != null) {
        let length2 = data.passport_number.length;
        let passport_number = data.passport_number.substring(length2 - 4);
        custom_id = contact_number + passport_number;

        return custom_id;
      } else {
        let length3 = data.nric_number.length;
        let nric_number = data.nric_number.substring(length3 - 4);
        custom_id = contact_number + nric_number;

        return custom_id;
      }
    },
    getSetting() {
      this.$store.dispatch("vmsPreferences2Module/getAllPreferences");
      this.$store.dispatch("vmsSettingsModule/getSettings");
    },
    getTimeOperator(data) {
      for (let index in this.visitorTypeOptions) {
        if (
          data?.vms_preferences_default_visitor_type ==
          this.visitorTypeOptions[index].name
        ) {
          var num =
            this.visitorTypeOptions[index].additional_metadata
              .visit_duration_minutes;
          var hours = num / 60;
          var rhours = Math.floor(hours);
          var minutes = (hours - rhours) * 60;
          var rminutes = Math.round(minutes);
          if (rhours != 0 && rminutes != 0) {
            this.visitInfo.visit_end_date = moment(
              this.visitInfo.visit_start_date +
                " " +
                this.visitInfo.visit_start_time
            )
              .add(rhours, "hours")
              .add(rminutes, "minutes")
              .format("YYYY-MM-DD");
            this.visitInfo.visit_end_time = moment()
              .add(rhours, "hours")
              .add(rminutes, "minutes")
              .format("HH:mm:ss");
          }
          if (rhours != 0 && rminutes == 0) {
            this.visitInfo.visit_end_date = moment(
              this.visitInfo.visit_start_date +
                " " +
                this.visitInfo.visit_start_time
            )
              .add(rhours, "hours")
              .format("YYYY-MM-DD");
            this.visitInfo.visit_end_time = moment()
              .add(rhours, "hours")
              .format("HH:mm:ss");
          }
          if (rhours == 0 && rminutes != 0) {
            this.visitInfo.visit_end_date = moment(
              this.visitInfo.visit_start_date +
                " " +
                this.visitInfo.visit_start_time
            )
              .add(rhours, "hours")
              .format("YYYY-MM-DD");
            this.visitInfo.visit_end_time = moment()
              .add(rminutes, "minutes")
              .format("HH:mm:ss");
          }
        }
      }
    },
    getTime(data) {
      switch (data?.vms_public_default_duration) {
        case "duration_time_range":
          this.visitInfo.visit_start_time = data?.vms_public_visit_start_time;
          this.visitInfo.visit_end_time = data?.vms_public_visit_end_time;
          break;
        case "duration_hours":
          this.visitInfo.visit_end_time = moment()
            .add(data?.vms_public_duration_hours, "hours")
            .format("HH:mm:ss");
          break;
        case "duration_end_time":
          this.visitInfo.visit_end_time = data?.vms_public_visit_end_time_only;
          break;
        case "duration_days":
          this.visitInfo.visit_end_date = moment()
            .add(data?.vms_public_duration_days, "days")
            .format("YYYY-MM-DD");
          break;
      }
    },
    getAccessGroups() {
      let access_groups = [];
      let result = [];
      access_groups =
        this.companysList?.access_metadata?.access_groups ??
        this.getPreference?.additional_metadata?.vms_preferences_access_groups;
      for (let index in access_groups) {
        result.push(
          this.companysList?.access_metadata?.access_groups?.[index]
            ?.access_group
        );
      }
      return result;
    },
    getAccessLevel() {
      let access_level = null;
      access_level =
        this.companysList?.access_metadata?.access_level?.access_level ??
        this.getPreference?.additional_metadata?.vms_preferences_access_level
          ?.access_level;
      return access_level;
    },
    getLiftAccessLevel() {
      let lift_access_level = null;
      lift_access_level =
        this.companysList?.access_metadata?.lift_access_level
          ?.lift_access_level;
      return lift_access_level;
    },
    getDeclarationForm() {
      let declaration_form_data = {};
      for (let i = 0; i < this.formRender?.length; i++) {
        declaration_form_data[i] = this.formRender?.[i]?.userData;
      }
      return declaration_form_data;
    },
    //handle
    handleSelectCompany(option) {
      this.selectedHostNameObj = null;
      this.isValidSelectedHostCompanyObj = false;

      let $this = this;

      if (!this.isPublic) {
        let API_URL =
          this.getAPIServerURL +
          "/api/visitorservice/company/?company_id=" +
          option.id;

        const client = this.$root.getAjaxFetchClient();

        client
          .getRequest(API_URL)
          .then((data) => {
            let validUrl = "";
            let result = data.result;
            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }
            this.companysList = result[validUrl];
          })
          .catch((err) => {
            $this.busy = false;
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            // if (err.status == 401) {
            //   $this.$store.dispatch("session/logoutSession");
            // }
          });
      }
    },
    handleChange(option) {
      if (this.parents_url === "create-visit") {
        for (let index in this.visitorTypeOptions) {
          if (this.visitorTypeOptions[index].name == option) {
            let endDate = null;
            let endTime = null;
            var num =
              this.visitorTypeOptions[index].additional_metadata
                .visit_duration_minutes;
            var hours = num / 60;
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            if (rhours != 0 && rminutes != 0) {
              endDate = moment(
                this.visitInfo.visit_start_date +
                  " " +
                  this.visitInfo.visit_start_time
              )
                .add(rhours, "hours")
                .add(rminutes, "minutes")
                .format("YYYY-MM-DD");
              endTime = moment()
                .add(rhours, "hours")
                .add(rminutes, "minutes")
                .format("HH:mm:ss");
            }
            if (rhours != 0 && rminutes == 0) {
              endDate = moment(
                this.visitInfo.visit_start_date +
                  " " +
                  this.visitInfo.visit_start_time
              )
                .add(rhours, "hours")
                .format("YYYY-MM-DD");
              endTime = moment().add(rhours, "hours").format("HH:mm:ss");
            }
            if (rhours == 0 && rminutes != 0) {
              endDate = moment(
                this.visitInfo.visit_start_date +
                  " " +
                  this.visitInfo.visit_start_time
              )
                .add(rminutes, "minutes")
                .format("YYYY-MM-DD");
              endTime = moment().add(rminutes, "minutes").format("HH:mm:ss");
            }

            console.log("endDate", endDate);
            console.log("endTime", endTime);

            this.visitInfo.visit_end_date = endDate;
            this.visitInfo.visit_end_time = endTime;
          }
        }
      }
    },
    handleResetForm() {
      this.hosts = [];
      this.companies = [];
      this.visitors = [];

      this.selectedHostNameObj = null;
      this.selectedHostCompanyObj = null;
      this.selectedVisitorObj = null;

      this.visitInfo.visit_purpose = null;

      if (this.$refs.referencesChild != undefined) {
        this.$refs.referencesChild.formAdd.face_image = null;
        this.$refs.referencesChild.formAdd.name = null;
        this.$refs.referencesChild.formAdd.email = null;
        this.$refs.referencesChild.formAdd.nric_number = null;
        this.$refs.referencesChild.formAdd.passport_number = null;
        this.$refs.referencesChild.formAdd.contact_number = null;
        this.$refs.referencesChild.formAdd.profile.gender = null;
        this.$refs.referencesChild.formAdd.profile.company = null;
        this.$refs.referencesChild.formAdd.profile.car_plate_no = null;
      }

      this.$v.$reset();
    },

    //set form
    setFormData: function (form_data) {
      let $this = this;

      let layoutType = "normal";
      let obj = JSON.parse(form_data);
      let obj2 = [];

      for (let key in obj) {
        if (
          obj[key].type === "text" ||
          obj[key].type === "textarea" ||
          obj[key].type === "number" ||
          obj[key].type === "autocomplete"
        ) {
          obj[key]["data-layout"] = layoutType;
          if (obj[key].required === false) {
            obj[key].required = true;
          }
        } else if (
          obj[key].type === "radio-group" ||
          obj[key].type === "checkbox-group" ||
          obj[key].type === "select" ||
          obj[key].type === "date"
        ) {
          if (obj[key].required == false) {
            obj[key].required = true;
          }
        }
        obj2.push(obj[key]);
      }
      $this.formRender.push(
        $("#declaration_form").formRender({
          dataType: "json",
          formData: obj2
        })
      );
    },

    //image
    showImage: function (img) {
      let $this = this;
      return img ? `data:image/jpeg;base64,${img}` : $this.defaultImage;
    },
    showAddVisitorImageModal2() {
      this.$bvModal.show("form-add-visitor-image-2");
      this.$nextTick(() => {
        this.$refs.referencesChild.getWebCamDeviceList();
      });
    },
    uploadImageRequired2() {
      //debugger; // eslint-disable-line no-debugger
      let result = null;
      let vms_registration_visitor_profile_picture_required = null;
      let face_image = this.isPublic
        ? this.$refs?.referencesChild?.formAdd?.face_image
        : this.selectedVisitorObj?.face_image ?? null;

      vms_registration_visitor_profile_picture_required =
        this.getPreference?.additional_metadata
          ?.vms_registration_visitor_profile_picture_required ??
        this.public_preference?.additional_metadata
          ?.vms_registration_visitor_profile_picture_required;

      switch (this.parents_url) {
        case "create-visit":
          vms_registration_visitor_profile_picture_required &&
          this.getPreference?.additional_metadata
            ?.vms_credential_operator_face_recognition &&
          face_image == null
            ? (result = true)
            : vms_registration_visitor_profile_picture_required &&
              !this.getPreference?.additional_metadata
                ?.vms_credential_operator_face_recognition &&
              face_image == null
            ? (result = true)
            : !vms_registration_visitor_profile_picture_required &&
              this.getPreference?.additional_metadata
                ?.vms_credential_operator_face_recognition &&
              face_image == null
            ? (result = true)
            : (result = false);
          break;
        case "kiosk2":
          vms_registration_visitor_profile_picture_required &&
          this.getPreference?.additional_metadata
            ?.vms_credential_kiosk_face_recognition &&
          face_image == null
            ? (result = true)
            : vms_registration_visitor_profile_picture_required &&
              !this.getPreference?.additional_metadata
                ?.vms_credential_kiosk_face_recognition &&
              face_image == null
            ? (result = true)
            : !vms_registration_visitor_profile_picture_required &&
              this.getPreference?.additional_metadata
                ?.vms_credential_kiosk_face_recognition &&
              face_image == null
            ? (result = true)
            : (result = false);
          break;
        default:
          result =
            vms_registration_visitor_profile_picture_required &&
            face_image == null;
          console.log("result", result);
      }
      return result;
    },

    stopCameraModal: function () {
      let $this = this;
      let tracks = null;
      if ($this.$refs?.referencesChild?.$refs?.cameraView) {
        if ($this.$refs?.referencesChild?.$refs?.cameraView?.lastChild) {
          tracks =
            $this.$refs?.referencesChild?.$refs?.cameraView?.lastChild?.srcObject.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
        }
      }
      this.countTakePhoto = 0;
    },
    removeCameraStream: function () {
      let $this = this;
      if ($this.$refs.referencesChild.$refs.cameraView != null) {
        while ($this.$refs.referencesChild.$refs.cameraView.hasChildNodes()) {
          $this.$refs.referencesChild.$refs.cameraView.removeChild(
            $this.$refs.referencesChild.$refs.cameraView.lastChild
          );
        }
      }
    },

    //add visitor modal
    showAddVisitorModal() {
      this.$bvModal.show("form-add-visitor");
      this.$nextTick(() => {
        this.$refs.referencesChild.formAdd.face_image = null;
        this.$v.$reset();
      });
    },
    showUpdateVisitorModal() {
      this.$bvModal.show("form-update-visitor");
      //this.$nextTick(() => {
      this.selectedIDType = this.selectedVisitorObj?.passport_number
        ? "passport_number"
        : "nric_number";
      this.selectedIDName =
        this.selectedVisitorObj?.passport_number ??
        this.selectedVisitorObj?.nric_number;
      this.updateShowUploadPhoto = true;
      // });
    },

    // validation
    validateMultiselect(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validateVisitInfo(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.visitInfo[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validationFormAccess() {
      let message = null;
      let access_groups_company =
        this.companysList?.access_metadata?.access_groups ?? null;
      let access_groups_setting =
        this.getPreference.additional_metadata.vms_preferences_access_groups ??
        null;
      let lift_access_level = null;

      switch (this.parents_url) {
        case "create-visit":
          lift_access_level =
            (this.getPreference?.additional_metadata
              ?.vms_credential_operator_lift_access_physical_card ||
              this.getPreference?.additional_metadata
                ?.vms_credential_operator_lift_access_static_qr ||
              this.getPreference?.additional_metadata
                ?.vms_credential_operator_lift_access_dynamic_qr ||
              this.getPreference?.additional_metadata
                ?.vms_credential_operator_lift_access_face_recognition) ??
            false;
          break;
        case "kiosk2":
          lift_access_level =
            (this.getPreference?.additional_metadata
              ?.vms_credential_kiosk_lift_access_physical_card ||
              this.getPreference?.additional_metadata
                ?.vms_credential_kiosk_lift_access_static_qr ||
              this.getPreference?.additional_metadata
                ?.vms_credential_kiosk_lift_access_dynamic_qr ||
              this.getPreference?.additional_metadata
                ?.vms_credential_kiosk_lift_access_face_recognition) ??
            false;
          break;
      }

      message =
        access_groups_company == null && access_groups_setting == null
          ? "Please setup for the company group access in SmartVMS settings."
          : access_groups_company != null && access_groups_setting == null
          ? "Please setup for the company group access in SmartVMS settings."
          : access_groups_company == null && access_groups_setting != null
          ? "Please setup for the company group access in SmartVMS settings."
          : null;

      message = lift_access_level
        ? "Please setup for the company lift access in SmartVMS settings."
        : 0;

      if (message != null && message != 0) {
        return this.popUpMessage(message);
      } else {
        return message;
      }
    },
    validationCredentialIssuance() {
      let $this = this;
      let message = null;
      let credentialIssuance = null;
      switch (this.parents_url) {
        case "create-visit":
          credentialIssuance =
            !$this.getPreference?.additional_metadata
              ?.vms_credential_operator_face_recognition &&
            !$this.getPreference?.additional_metadata
              ?.vms_credential_operator_static_qr &&
            !$this.getPreference?.additional_metadata
              ?.vms_credential_operator_dynamic_qr;
          break;
        default:
          credentialIssuance =
            !$this.getPreference?.additional_metadata
              ?.vms_credential_kiosk_face_recognition &&
            !$this.getPreference?.additional_metadata
              ?.vms_credential_kiosk_static_qr &&
            !$this.getPreference?.additional_metadata
              ?.vms_credential_kiosk_dynamic_qr;
      }

      if (credentialIssuance) {
        message = "Please setup for credential issuance in SmartVMS settings.";
        this.popUpMessage(message);
      } else {
        return true;
      }
    },
    validationCreateVisit() {
      let message = null;
      let uploadImageRequired = this.uploadImageRequired2();
      let validationDeclarationForm = this.onSubmitValidationDeclarationForm();

      if (this.parents_url == "create-visit" || this.parents_url == "kiosk2") {
        if (uploadImageRequired && !this.isValidSelectedVisitorObj) {
          message = "Please take a picture for profile picture.";
          this.popUpMessage(message);
        }
      }

      if (this.parents_url == "create-visit") {
        if (
          !this.isValidSelectedVisitorObj &&
          this.getPreference?.additional_metadata
            ?.vms_credential_operator_license_plate_recognition &&
          !this.selectedVisitorObj?.profile?.car_plate_no &&
          !(
            !this.isValidSelectedVisitorObj &&
            this.getPreference?.additional_metadata
              ?.vms_credential_operator_fingerprint &&
            (!this.selectedVisitorObj?.profile?.fingerprint ||
              (!this.selectedVisitorObj?.profile?.fingerprint?.template1 &&
                !this.selectedVisitorObj?.profile?.fingerprint?.template2))
          )
        ) {
          this.$bvModal.show("form-error-message-car-plate-no-operator");
        }

        if (
          !this.isValidSelectedVisitorObj &&
          this.getPreference?.additional_metadata
            ?.vms_credential_operator_fingerprint &&
          (!this.selectedVisitorObj?.profile?.fingerprint ||
            (!this.selectedVisitorObj?.profile?.fingerprint?.template1 &&
              !this.selectedVisitorObj?.profile?.fingerprint?.template2)) &&
          !(
            !this.isValidSelectedVisitorObj &&
            this.getPreference?.additional_metadata
              ?.vms_credential_operator_license_plate_recognition &&
            !this.selectedVisitorObj?.profile?.car_plate_no
          )
        ) {
          this.$bvModal.show("form-error-message-fingerprint-operator");
        }

        if (
          !this.isValidSelectedVisitorObj &&
          this.getPreference?.additional_metadata
            ?.vms_credential_operator_license_plate_recognition &&
          !this.selectedVisitorObj?.profile?.car_plate_no &&
          !this.isValidSelectedVisitorObj &&
          this.getPreference?.additional_metadata
            ?.vms_credential_operator_fingerprint &&
          (!this.selectedVisitorObj?.profile?.fingerprint ||
            (!this.selectedVisitorObj?.profile?.fingerprint?.template1 &&
              !this.selectedVisitorObj?.profile?.fingerprint?.template2))
        ) {
          this.$bvModal.show("form-error-message-both-operator");
        }
      }

      if (this.parents_url == "kiosk2") {
        if (!validationDeclarationForm) {
          message = "Please fill in a required field.";
          this.popUpMessage(message);
        }

        if (
          !this.isValidSelectedVisitorObj &&
          this.getPreference?.additional_metadata
            ?.vms_credential_kiosk_license_plate_recognition &&
          !this.selectedVisitorObj?.profile?.car_plate_no
        ) {
          this.$bvModal.show("form-error-message-car-plate-no-kiosk");
        }

        if (
          !this.isValidSelectedVisitorObj &&
          this.getPreference?.additional_metadata
            ?.vms_credential_kiosk_fingerprint &&
          (!this.selectedVisitorObj?.profile?.fingerprint ||
            (!this.selectedVisitorObj?.profile?.fingerprint?.template1 &&
              !this.selectedVisitorObj?.profile?.fingerprint?.template2))
        ) {
          this.$bvModal.show("form-error-message-fingerprint-kiosk");
        }

        if (
          !this.isValidSelectedVisitorObj &&
          this.getPreference?.additional_metadata
            ?.vms_credential_kiosk_license_plate_recognition &&
          !this.selectedVisitorObj?.profile?.car_plate_no &&
          !this.isValidSelectedVisitorObj &&
          this.getPreference?.additional_metadata
            ?.vms_credential_kiosk_fingerprint &&
          (!this.selectedVisitorObj?.profile?.fingerprint ||
            (!this.selectedVisitorObj?.profile?.fingerprint?.template1 &&
              !this.selectedVisitorObj?.profile?.fingerprint?.template2))
        ) {
          this.$bvModal.show("form-error-message-both-kiosk");
        }
      }
    },
    onSubmitValidationForm() {
      let $this = this;
      let result = null;
      let passport_number = null;
      let nric_number = null;
      let uploadImageRequired = this.uploadImageRequired2();
      let license_plate_recognition =
        this.parents_url == "create-visit"
          ? this.getPreference?.additional_metadata
              ?.vms_credential_operator_license_plate_recognition &&
            !this.selectedVisitorObj?.profile?.car_plate_no
          : this.getPreference?.additional_metadata
              ?.vms_credential_kiosk_license_plate_recognition &&
            !this.selectedVisitorObj?.profile?.car_plate_no;
      let fingerprint =
        this.parents_url == "create-visit"
          ? this.getPreference?.additional_metadata
              ?.vms_credential_operator_fingerprint &&
            (!this.selectedVisitorObj?.profile?.fingerprint ||
              (!this.selectedVisitorObj?.profile?.fingerprint.template1 &&
                !this.selectedVisitorObj?.profile?.fingerprint.template2))
          : this.getPreference?.additional_metadata
              ?.vms_credential_kiosk_fingerprint &&
            (!this.selectedVisitorObj?.profile?.fingerprint ||
              (!this.selectedVisitorObj?.profile?.fingerprint.template1 &&
                !this.selectedVisitorObj?.profile?.fingerprint.template2));

      this.$v.$touch();
      switch (this.isPublic) {
        case true:
          this.$refs.referencesChild.$v.$touch();
          passport_number =
            this.$refs?.referencesChild?.$v?.formAdd?.passport_number?.$model;
          nric_number =
            this.$refs?.referencesChild?.$v?.formAdd?.nric_number?.$model;

          result = this.isValidSelectedHostCompanyObj
            ? false
            : this.isValidSelectedHostNameObj
            ? false
            : passport_number == null &&
              nric_number == null &&
              uploadImageRequired
            ? false
            : true;

          break;
        default:
          result = this.isValidSelectedHostCompanyObj
            ? false
            : this.isValidSelectedHostNameObj
            ? false
            : this.isValidSelectedVisitorObj
            ? false
            : uploadImageRequired
            ? false
            : license_plate_recognition
            ? false
            : fingerprint
            ? false
            : $this.$v.$anyError
            ? false
            : true;
      }

      return result;
    },
    onSubmitValidationDeclarationForm() {
      let $this = this;
      let count = 0;
      let result = null;

      for (let key in $this.formRender) {
        $this.formElement = $this.formRender[key].userData;
      }

      for (let key2 in $this.formElement) {
        if (
          $this.formElement[key2].required != undefined &&
          $this.formElement[key2].required == true
        ) {
          $this.userData = [];
          $this.userData.push($this.formElement[key2].userData);
        }
      }

      for (let key3 in $this.userData) {
        if ($this.userData[key3] == "" || $this.userData[key3] == undefined) {
          count++;
        }
      }

      result = count > 0 ? false : true;
      return result;
    },
    // create visit - step 1
    submitAndCreateVisit() {
      this.isValidSelectedHostCompanyObj =
        this.selectedHostCompanyObj == null ? true : false;
      this.isValidSelectedHostNameObj =
        this.selectedHostNameObj == null ? true : false;
      this.isValidSelectedVisitorObj =
        this.selectedVisitorObj == null ? true : false;

      let $this = this;
      let validForm = this.onSubmitValidationForm();
      let declarationForm =
        this.getPreference?.additional_metadata
          ?.vms_registration_declaration_form ?? false;
      let validDeclarationForm =
        declarationForm && this.parents_url == "kiosk2"
          ? this.onSubmitValidationDeclarationForm()
          : null;
      let declaration_form_data = validDeclarationForm
        ? this.getDeclarationForm()
        : {};
      let data = {};
      data = {
        ...$this.visitInfo,
        host_ids: [$this.selectedHostNameObj?.id],
        visitor_ids: [$this.selectedVisitorObj?.id],
        status: "APPROVED",
        additional_metadata: {
          visitor: {
            [$this.selectedVisitorObj?.id]: {
              declaration_form_data: declaration_form_data
            }
          }
        }
      };
      console.log("declarationForm", declarationForm);
      console.log("validDeclarationForm", validDeclarationForm);
      console.log("validForm", validForm);
      console.log("declaration_form_data", declaration_form_data);
      console.log("data", data);

      if (this.parents_url == "create-visit") {
        if (validForm) {
          this.formAddAccess(),
            this.form.lift_access_level != null &&
            this.form.access_groups.length != 0 &&
            this.form.access_types.length != 0
              ? this.addVisitRegistration(data)
              : null;
        } else {
          this.validationCreateVisit();
        }
      }

      if (this.parents_url == "kiosk2") {
        if (
          validForm &&
          (validDeclarationForm || validDeclarationForm == null)
        ) {
          this.formAddAccess(),
            this.form.lift_access_level != null &&
            this.form.access_groups.length != 0 &&
            this.form.access_types.length != 0
              ? this.addVisitRegistration(data)
              : null;
        } else {
          this.validationCreateVisit();
        }
      }

      if (this.isPublic) {
        if (
          validForm &&
          this.visit_data == null &&
          this.isValidSelectedVisitorObj
        ) {
          this.$refs.referencesChild.onSubmitAddForm();
        } else if (
          validForm &&
          this.visit_data == null &&
          this.isValidSelectedVisitorObj == false
        ) {
          this.addVisitRegistration(data);
        } else {
          this.validationCreateVisit();
        }
      }
    },
    // create visit - step 2
    formAddAccess() {
      this.form.access_types =
        this.parents_url == "create-visit"
          ? this.$store.getters[
              "vmsPreferences2Module/operatorPresetCredential"
            ]
          : this.$store.getters["vmsPreferences2Module/kioskPresetCredential"];

      switch (this.parents_url) {
        case "create-visit":
          this.form.qr_code_type = this.getPreference?.additional_metadata
            ?.vms_credential_operator_dynamic_qr
            ? "dynamic"
            : "static";
          this.form.access_types.length == 0
            ? this.validationCredentialIssuance()
            : this.form.access_types;
          break;
        case "kiosk2":
          this.form.qr_code_type = this.getPreference?.additional_metadata
            ?.vms_credential_kiosk_dynamic_qr
            ? "dynamic"
            : "static";
          this.form.access_types.length == 0
            ? this.validationCredentialIssuance()
            : this.form.access_types;
          break;
        default:
      }
      this.form.access_groups =
        this.getAccessGroups() ?? this.validationFormAccess();
      this.form.access_level =
        this.getAccessLevel() ?? this.validationFormAccess();
      this.form.lift_access_level =
        this.getLiftAccessLevel() ?? this.validationFormAccess();

      console.log("this.form", this.form);
    },
    // create visit - step 3
    addVisitRegistration(data) {
      let physicalCard =
        this.parents_url == "create-visit"
          ? this.getPreference?.additional_metadata
              ?.vms_credential_operator_physical_card ?? false
          : this.getPreference?.additional_metadata
              ?.vms_credential_kiosk_physical_card ?? false;

      console.log("physicalCard", physicalCard);

      let API_URL = this.isPublic
        ? this.getAPIServerURL + "/public/vms/new-visit/"
        : this.getAPIServerURL + "/api/visitorservice/visit/";
      const client = this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, data)
        .then(async (data) => {
          let error = data?.detail;
          if (error != undefined) {
            this.$bvModal.msgBoxOk(error, {
              centered: true
            });
            return false;
          } else {
            let validUrl = null;
            outer_loop: for (let key in data?.result) {
              validUrl = key;
              break outer_loop;
            }
            this.visit_data = data?.result?.[validUrl];
            console.log("visit_create_success", this.visit_data);

            let carPlateNo =
              this.selectedVisitorObj?.profile?.car_plate_no?.match(
                "^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$"
              );
            console.log("carPlateNo", carPlateNo);

            this.isPublic || physicalCard
              ? this.gotToNextPage()
              : carPlateNo == null
              ? setTimeout(() => {
                  console.log("Wait 1 second before proceed checkin");
                  this.checkIn();
                }, 2000)
              : setTimeout(() => {
                  console.log("Wait 1 second before proceed add access");
                  this.submitAddAccessForm();
                }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // create visit - step 4
    submitAddAccessForm() {
      let $this = this;
      let fingerprint_template1 = this.selectedVisitorObj?.profile?.fingerprint
        ? this.selectedVisitorObj?.profile?.fingerprint.template1
        : null;
      let fingerprint_template2 = this.selectedVisitorObj?.profile?.fingerprint
        ? this.selectedVisitorObj?.profile?.fingerprint.template2
        : null;

      let data = {
        visit_id: this.visit_data.visit_id,
        visitor_id: this.selectedVisitorObj.id,
        face_image: this.selectedVisitorObj.face_image,
        qr_code_type: this.form.qr_code_type,
        access_level: this.form.access_level,
        access_types: this.form.access_types,
        access_groups: this.form.access_groups,
        lift_access_level: this.form.lift_access_level,
        car_plate_no: this.selectedVisitorObj?.profile?.car_plate_no ?? "-",
        //new addition
        access_card_no: null,
        as_format: "base64",
        fingerprint_template_1: fingerprint_template1,
        fingerprint_template_2: fingerprint_template2
      };
      console.log("data access", data);
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/visit/access/";
      const client = $this.$root.getAjaxFetchClient();
      if (!this.form.access_types.includes("card")) {
        client
          .postRequest(API_URL, data)
          .then((data) => {
            this.formErrorMessage = data?.detail;
            if (this.formErrorMessage != undefined) {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: data?.detail,
                message_type: "danger"
              });
              return false;
            } else {
              let validUrl = null;
              outer_loop: for (var key in data?.result) {
                validUrl = key;
                break outer_loop;
              }

              console.log("access result", data?.result?.[validUrl]);
              if (data?.result?.[validUrl]) {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Visit has been added successfully",
                  message_type: "success"
                });

                console.log("go to visit details");
                this.gotToNextPage();

                /* let checkIn =
                  this.getPreference?.additional_metadata
                    ?.vms_check_in_as_soon_as_credential_is_issued ?? false;
                console.log("checkIn", checkIn);

                checkIn &&
                this.visit_data != null &&
                this.validationCredentialIssuance()
                  ? setTimeout(() => {
                      console.log("Wait 1 second before proceed checkin");
                      this.checkIn();
                    }, 2000)
                  : console.log("go to visit details");
                this.gotToNextPage(); */
              }
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        console.log("go to visit details");
        this.gotToNextPage();
      }
    },
    // create visit - step 5
    checkIn() {
      try {
        let $this = this;
        let API_URL =
          $this.getAPIServerURL +
          "/api/visitorservice/visit/check-in/?visit_id=" +
          this.visit_data.visit_id;

        const client = $this.$root.getAjaxFetchClient();
        client
          .postRequest(API_URL, null)
          .then(async (data) => {
            let validUrl = null;
            outer_loop: for (let key in data?.result) {
              validUrl = key;
              break outer_loop;
            }
            this.checkIn_data = data?.result?.[validUrl];
            console.log("checkIn_success ", this.checkIn_data);
            if (this.checkIn_data == null) {
              //console.log("go to visit details");

              let physicalCard =
                this.parents_url == "create-visit"
                  ? this.getPreference?.additional_metadata
                      ?.vms_credential_operator_physical_card ?? false
                  : this.getPreference?.additional_metadata
                      ?.vms_credential_kiosk_physical_card ?? false;

              this.isPublic || physicalCard
                ? this.gotToNextPage()
                : setTimeout(() => {
                    console.log("Wait 1 second before proceed add access");
                    this.submitAddAccessForm();
                  }, 1000);
            }
          })
          .catch((error) => {
            console.log("data_error", error);
          });
      } catch (e) {
        console.log("e", e);
      }
    },
    // create visit - step 6
    gotToNextPage() {
      this.isPublic
        ? this.$router
            .push({
              name: "/public/success",
              query: this.visit_data
            })
            .catch(() => {})
        : this.$router
            .push({
              name: "visit-detail",
              params: {
                id: this.visit_data.visit_id
              },
              query: {
                parents_url: this.parents_url
              }
            })
            .catch(() => {});
    },
    popUpMessage(message) {
      let result = null;
      this.$bvModal
        .msgBoxOk(message, {
          centered: true
        })
        .then((value) => {
          if (value) {
            this.uploadImageRequired2() && !this.isValidSelectedVisitorObj
              ? this.showAddVisitorImageModal2()
              : (result = false);
          }
        });
      return result;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.visitor-face-image {
  width: 100px;
  height: 100px;

  background-color: var(--colour-bg);
  border-radius: $border-radius-card;
  border: 3px solid $white;
  overflow: hidden;
  cursor: pointer;
}

.visitor-card-face-image {
  max-width: 100px;
  height: 100px;
  display: flex;
  align-items: center;

  border: 3px solid $white;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.invalid-contact-number-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d53e4c;
}

.invalid .multiselect__tags {
  border-color: #ef5350 !important;
}
.background-button {
  background-color: var(--colour-bg);
  border: none;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  /* width: 100%; */
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
}
</style>
